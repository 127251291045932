import React, { useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import Fab from "@material-ui/core/Fab";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useFirebase } from "../../../components/FirebaseProvider";
import useStyles from "./styles";

import MaterialTable from "@material-table/core";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { useSnackbar } from "notistack";
import AppPageLoading from "../../../components/AppPageLoading";
import errorMessageFactory from "../../../utils/errorMessageFactory";
import { formatDate } from "../../../utils/formatter";
import getTableTitle from "../../../utils/getTableTitle";
import AddDialogC from "./addCategory";
import AddDialogN from "./addNews";

const columns = {
  kategori: [
    { title: getTableTitle("Id"), field: "id" },
    { title: getTableTitle("Title"), field: "title" },
    {
      title: getTableTitle("Dibuat"),
      field: "createdAt",
      defaultSort: "desc",
      render: (rowData) => <span>{formatDate(rowData.createdAt)}</span>,
    },
    { title: getTableTitle("Berita Yang Di Pin"), field: "pinNews.title" },
  ],

  status: [
    { title: getTableTitle("Judul"), field: "title" },
    { title: getTableTitle("Kategori"), field: "kategori.title" },
    { title: getTableTitle("Fee"), field: "fee", defaultSort: "desc" },
    {
      title: getTableTitle("Dibuat"),
      field: "createdAt",
      defaultSort: "desc",
      render: (rowData) => <span>{formatDate(rowData.createdAt)}</span>,
    },
    {
      title: getTableTitle("Diubah"),
      field: "updatedAt",

      render: (rowData) => <span>{formatDate(rowData.updatedAt)}</span>,
    },
    {
      title: getTableTitle("Diterbitkan"),
      field: "publishedAt",

      render: (rowData) => <span>{formatDate(rowData.publishedAt)}</span>,
    },
  ],
};

function Tabel() {
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { firestore, Arr } = useFirebase();

  const queryNews = firestore
    .collection("news")
    .where("status", "==", params.status);
  const [data, loading] = useCollectionData(queryNews, { idField: "uid" });

  const table = {
    columns: params.status === "kategori" ? columns.kategori : columns.status,
    data: [],
  };

  // const [table, setTable] = useState({
  //   columns: params.status === "kategori" ? columns.kategori : columns.status,
  //   data: [],
  // });

  const [kategoriDialog, setKategoriDialog] = useState({
    open: false,
    mode: "Tambah",
    data: {},
  });

  const [openAddDialogN, setOpenAddDialogN] = useState(false);

  // useEffect(() => {
  //     if (snapshot && !snapshot.empty) {
  //         if (params.status === "kategori") {
  //             setTable(table => {
  //                 return {
  //                     ...table,
  //                     columns: columns.kategori,
  //                     data: snapshot.docs[0].data().items
  //                 }
  //             })
  //         } else {
  //             setTable(table => {
  //                 return {
  //                     ...table,
  //                     columns: columns.status,
  //                     data: snapshot.docs.map(doc => {
  //                         return {
  //                             uid: doc.id,
  //                             ...doc.data()
  //                         }
  //                     })
  //                 }
  //             })
  //         }
  //     } else {
  //         setTable(table => {
  //             return {
  //                 ...table,
  //                 columns: params.status === "kategori" ? columns.kategori : columns.status,
  //                 data: []
  //             }
  //         })
  //     }
  // }, [snapshot, params.status]);

  const columnsSelected = useMemo(
    () => (params.status === "kategori" ? columns.kategori : columns.status),
    [params.status]
  );
  const dataSelected = params.status === "kategori" ? data?.[0]?.items : data;
  if (loading) {
    return <AppPageLoading />;
  }

  let title = "Berita Diterbitkan";

  if (params.status === "draft") {
    title = "Draf Berita";
  }
  if (params.status === "trash") {
    title = "Berita Dihapus";
  }
  if (params.status === "kategori") {
    title = "Kategori Berita";
  }

  return (
    <>
      <MaterialTable
        options={{ thirdSortClick: false }}
        title={title}
        columns={columnsSelected}
        data={dataSelected.map((row, index) => ({ ...row, id: index }))}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit",
            hidden: params.status === "trash",
            onClick: (event, data) => {
              if (params.status === "kategori") {
                setKategoriDialog({
                  mode: "Edit",
                  open: true,
                  data,
                });
              } else {
                history.push(`/news/edit/${data.uid}`);
              }
            },
          },
          {
            icon: "delete",
            tooltip: "Hapus",
            hidden: params.status === "trash",
            onClick: async (event, data) => {
              try {
                if (params.status === "kategori") {
                  if (!data.pinNews && !data.updatedAt) {
                    await firestore.doc(`news/kategori`).update({
                      items: Arr.arrayRemove({
                        id: data.id,
                        title: data.title,
                        createdAt: data.createdAt,
                      }),
                    });
                  } else {
                    await firestore.doc(`news/kategori`).update({
                      items: Arr.arrayRemove({
                        id: data.id,
                        title: data.title,
                        createdAt: data.createdAt,
                        pinNews: data.pinNews,
                        updatedAt: data.updatedAt,
                      }),
                    });
                  }
                } else {
                  await firestore
                    .doc(`news/${data.uid}`)
                    .set({ status: "trash" }, { merge: true });
                }
              } catch (e) {
                const message = errorMessageFactory(e);
                enqueueSnackbar(message, { variant: "error" });
              }
            },
          },
          {
            icon: "restore",
            tooltip: "Kembalikan",
            hidden:
              params.status === "published" ||
              params.status === "draft" ||
              params.status === "kategori",
            onClick: async (event, data) => {
              await firestore
                .doc(`news/${data.uid}`)
                .set({ status: "draft" }, { merge: true });
            },
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: title,
            addTooltip: "Tambah",
            editTooltip: "Ubah",
            deleteTooltip: "Hapus",
            editRow: {
              deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
              cancelTooltip: "Batal",
              saveTooltip: "Ya",
            },
          },
          toolbar: {
            searchTooltip: "Cari",
            searchPlaceholder: "Cari Data",
          },
          header: {
            actions: "Tindakan",
          },
          pagination: {
            labelRowsSelect: "Baris",
            labelDisplayedRows: " {from}-{to} Dari {count} Baris",
            firstTooltip: "Halaman Pertama",
            previousTooltip: "Halaman Sebelumnya",
            nextTooltip: "Halaman Selanjutnya",
            lastTooltip: "Halaman Terakhir",
          },
        }}
      />
      {params.status === "kategori" && (
        <>
          <Fab
            className={classes.fab2}
            color="primary"
            onClick={() => {
              setKategoriDialog({
                mode: "Tambah",
                open: true,
                data: {},
              });
            }}
          >
            <CategoryOutlinedIcon />
          </Fab>

          <AddDialogC
            open={kategoriDialog.open}
            handleClose={() => {
              setKategoriDialog({
                //mode: 'Tambah',
                open: false,
                data: {},
              });
            }}
            items={table.data}
            mode={kategoriDialog.mode}
            data={kategoriDialog.data}
          />
        </>
      )}

      {params.status !== "kategori" && (
        <>
          <Fab
            className={classes.fab1}
            color="primary"
            onClick={() => {
              setOpenAddDialogN(true);
            }}
            tooltip="true"
          >
            <PostAddIcon />
          </Fab>
          <AddDialogN
            open={openAddDialogN}
            handleClose={() => {
              setOpenAddDialogN(false);
            }}
            //items={table.data}
          />
        </>
      )}
    </>
  );
}

export default Tabel;
