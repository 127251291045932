import React, { useEffect, useState } from "react";

// MUI
// import Fab from '@material-ui/core/Fab';
// import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import MaterialTable from "@material-table/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

// Component
import update from "immutability-helper";
import { useSnackbar } from "notistack";
import AppPageLoading from "../../../../components/AppPageLoading";
import SelectKampus from "../../../../components/AppSelect/universitas";
import { firestore } from "../../../../components/FirebaseProvider";
import errorMessageFactory from "../../../../utils/errorMessageFactory";
import getTableTitle from "../../../../utils/getTableTitle";

function Jurusan() {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [table, setTable] = React.useState({
    columns: [
      { title: getTableTitle("ID"), field: "jurusan_id" },
      { title: getTableTitle("Nama"), field: "nama_jurusan" },
      { title: getTableTitle("Kelompok"), field: "kelompok" },
      { title: getTableTitle("Nilai Minimal"), field: "nilai_min" },
      { title: getTableTitle("Kuota"), field: "kuota" },
    ],
    data: [],
  });

  const [filter, setFilter] = useState({
    ptn: {
      nama: "",
      ptn_id: "",
    },
  });

  const handleClear = () => {
    setFilter({
      ptn: {
        nama: "",
        ptn_id: "",
      },
    });
  };

  useEffect(() => {
    if (filter.ptn.ptn_id) {
      const querySekolah = async () => {
        setLoading(true);
        const snapshot = await firestore
          .collection("jurusan")
          .where("ptn_id", "==", filter.ptn.ptn_id)
          .get();

        setTable((table) => {
          return {
            ...table,
            data: snapshot.docs.map((doc) => {
              return {
                uid: doc.id,
                ...doc.data(),
              };
            }),
          };
        });
        setLoading(false);
      };
      querySekolah();
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [filter.ptn.ptn_id]);

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilter((form) => ({
      ...form,
      [name]: value,
    }));
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={5}
        style={{ margin: 10 }}
      >
        <Grid item xs={6}>
          <SelectKampus
            value={{ label: filter.ptn.nama, value: filter.ptn.ptn_id }}
            onChange={({ value, label }) => {
              handleChangeFilter({
                target: {
                  value: {
                    nama: label,
                    ptn_id: value,
                  },
                  name: "ptn",
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleClear}>
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>
      {loading ? (
        <AppPageLoading />
      ) : filter.ptn.ptn_id ? (
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            style={{ marginBottom: 10 }}
          >
            <Grid item style={{ fontSize: 35 }}>
              <ReportProblemOutlinedIcon color="secondary" fontSize="inherit" />
            </Grid>
            <Grid item style={{ textAlign: "left" }}>
              <Typography variant="caption">
                Saat Anda Menambah Data Baru, Data Universitas Akan Diambil Dari
                Pilihan Diatas.
              </Typography>
              <br />
              <Typography variant="caption">
                Pastikan Pilihan Anda Sesuai Dengan Data Yang Akan Ditambahkan
              </Typography>
            </Grid>
          </Grid>

          <MaterialTable
            options={{ thirdSortClick: false }}
            title="Data Jurusan"
            columns={table.columns}
            data={table.data}
            editable={{
              onRowAdd: async (newData) => {
                try {
                  if (
                    !newData.jurusan_id ||
                    !newData.kelompok ||
                    !newData.kuota ||
                    !newData.nama_jurusan ||
                    !newData.nilai_min
                  ) {
                    enqueueSnackbar("Data Harus Lengkap", { variant: "error" });

                    throw new Error("Data Harus Lengkap");
                  }
                  const newColData = { ...newData, ptn_id: filter.ptn.ptn_id };
                  const newSekolah = await firestore
                    .collection("jurusan")
                    .add(newColData);

                  setTable((table) => {
                    return {
                      ...table,
                      data: [
                        { ...newColData, uid: newSekolah.id },
                        ...table.data,
                      ],
                    };
                  });
                  enqueueSnackbar("Data Berhasil Ditambahkan", {
                    variant: "success",
                  });
                } catch (e) {
                  const message = errorMessageFactory(e);
                  enqueueSnackbar(message, { variant: "error" });
                }
              },

              onRowUpdate: async (newData, oldData) => {
                if (
                  !newData.jurusan_id ||
                  !newData.kelompok ||
                  !newData.kuota ||
                  !newData.nama_jurusan ||
                  !newData.nilai_min ||
                  !newData.ptn_id
                ) {
                  enqueueSnackbar("Data Harus Lengkap", { variant: "error" });

                  throw new Error("Data Harus Lengkap");
                }

                await firestore
                  .doc(`jurusan/${oldData.uid}`)
                  .set(newData, { merge: true });
                setTable((table) => {
                  return {
                    ...table,
                    data: update(table.data, {
                      [oldData.tableData.id]: { $set: newData },
                    }),
                  };
                });
                enqueueSnackbar("Data Berhasil Diubah", { variant: "success" });
              },
              onRowDelete: async (oldData) => {
                await firestore.doc(`jurusan/${oldData.uid}`).delete();
                setTable((table) => {
                  return {
                    ...table,
                    data: update(table.data, {
                      $splice: [[oldData.tableData.id, 1]],
                    }),
                  };
                });
                enqueueSnackbar("Data Berhasi Dihapus", { variant: "success" });
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Tidak Ada Data",
                addTooltip: "Tambah",
                editTooltip: "Ubah",
                deleteTooltip: "Hapus",
                editRow: {
                  deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
                  cancelTooltip: "Batal",
                  saveTooltip: "Ya",
                },
              },
              toolbar: {
                searchTooltip: "Cari",
                searchPlaceholder: "Cari Data",
              },
              header: {
                actions: "Tindakan",
              },
              pagination: {
                labelRowsSelect: "Baris",
                labelDisplayedRows: " {from}-{to} Dari {count} Baris",
                firstTooltip: "Halaman Pertama",
                previousTooltip: "Halaman Sebelumnya",
                nextTooltip: "Halaman Selanjutnya",
                lastTooltip: "Halaman Terakhir",
              },
            }}
          />
        </>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ margin: "10% 0", paddingBottom: "7.5%" }}
        >
          <Grid item style={{ fontSize: 40 }}>
            <InfoOutlinedIcon htmlColor="grey" fontSize="inherit" />
          </Grid>
          <Grid item>
            <Typography>Silahkan Pilih Universitas Terlebih Dahulu</Typography>
            <Typography variant="caption">
              Data Jurusan Akan Tampil Sesuai Universitas Yang Dipilih
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Jurusan;
