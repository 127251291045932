import { Divider, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { firestore } from "../../../components/FirebaseProvider";
import { Numberformat } from "../../../components/NumberFormat";
import errorMessageFactory from "../../../utils/errorMessageFactory";
import useStyles from "./styles";

export default function DialogCoin({ dialog: { open, data }, handleClose }) {
  const [coin, setCoin] = React.useState("");

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [reason, setReason] = useState({
    open: false,
  });
  const [alasan, setAlasan] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const handleValid = async () => {
    try {
      // let Koin = null;
      // if (coin !== "") {
      //   Koin = parseInt(coin);
      // }
      // if (coin === "") {
      //   Koin = parseInt(data.jumlah_coin);
      // }
      // await firestore.doc(`koin_siswa/${data.siswa_id}`).set({
      //     coin: Arr.increment(Koin)
      // }, { merge: true })
      await firestore.doc(`misi/${data.uid}`).set(
        {
          status: "pending-valid",
        },
        { merge: true }
      );
      enqueueSnackbar("Misi Telah Divalidasi", { variant: "success" });
    } catch (e) {
      enqueueSnackbar(`Gagal validasi misi, ${e?.message}`, {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  const handleRejected = async () => {
    await firestore.doc(`misi/${data.uid}`).set(
      {
        status: "rejected",
      },
      { merge: true }
    );
    setReason((reason) => {
      return {
        ...reason,
        open: true,
      };
    });
    handleClose();
    enqueueSnackbar("Misi Tidak Divalidasi", { variant: "error" });
  };

  const handleClickView = () => {
    return window.open(data.photoURL);
  };

  const hadleAlasan = async (e) => {
    e.preventDefault();

    setSubmitting(true);
    try {
      await firestore.doc(`misi/${data.uid}`).set(
        {
          keterangan: alasan,
        },
        { merge: true }
      );
      setReason((reason) => {
        return {
          ...reason,
          open: false,
        };
      });
      enqueueSnackbar(`Alasan dibatalkan telah dikirim`, {
        variant: "success",
      });
    } catch (e) {
      const message = errorMessageFactory(e);
      enqueueSnackbar(message, { variant: "error" });
    }
    setSubmitting(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{ textAlign: "center", fontWeight: "bold" }}
          id="alert-dialog-title"
        >
          {"Review Misi"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid style={{ margin: "auto" }}>
            <Grid item>
              <img
                onClick={handleClickView}
                width="100%"
                src={data.photoURL}
                alt="Gambar Bukti Transaksi"
              />
              <br />
            </Grid>
            <Grid item>
              <br />
              <Typography variant="body2">
                <b>{data.misi && data.misi.judul}</b>
              </Typography>
              <br />
              <Typography variant="body2">
                <b>Info Data Siswa</b>
              </Typography>
              <Typography variant="caption">
                Nama : {data.siswa ? data.siswa.nama : <i>Tidak Ada Nama</i>}
              </Typography>
              <br />
              <Typography variant="caption">
                Email: {data.siswa ? data.siswa.email : <i>Tidak Ada Email</i>}
              </Typography>
              <br />
              <br />
            </Grid>
            <Divider />
            <Grid style={{ marginTop: 30, marginBottom: 30 }}>
              <TextField
                name="coin"
                variant="outlined"
                fullWidth
                label="Jumlah Coin"
                onChange={(e) => setCoin(e.target.value)}
                defaultValue={data.jumlah_coin}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Coin</InputAdornment>
                  ),
                  inputComponent: Numberformat,
                }}
                color="primary"
              />
            </Grid>
            <DialogActions style={{ padding: 0, marginBottom: 20 }}>
              <Button
                onClick={handleRejected}
                fullWidth
                size="large"
                variant="contained"
                style={{ backgroundColor: "#c0392b", color: "white" }}
              >
                Invalid
              </Button>
              <Button
                onClick={handleValid}
                fullWidth
                size="large"
                variant="contained"
                color="primary"
              >
                Valid
              </Button>
            </DialogActions>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={reason.open}
        aria-labelledby="alert-dialog-ttl"
        aria-describedby="alert-dialog-desc"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-ttl">
          Misi
        </DialogTitle>
        <DialogContent dividers>
          <Grid className={classes.wrapContent}>
            <Grid className={classes.wrapBody}>
              <Typography align="left">Sertakan Alasan Menolak Misi</Typography>
              <TextField
                style={{ marginTop: 15 }}
                id="reason"
                name="reason"
                value={alasan}
                onChange={(e) => setAlasan(e.target.value)}
                label="Alasan Menolak Misi"
                multiline
                rows="4"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <DialogActions className={classes.wrapActions}>
              <Button
                fullWidth
                onClick={hadleAlasan}
                disabled={isSubmitting}
                size="large"
                variant="contained"
                color="primary"
              >
                Kirim
              </Button>
            </DialogActions>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
