// React
import React, { useCallback, useEffect, useState } from "react";

//
import useStyles from "./styles";

//
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

// Utils
import axios from "axios";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { useDocument } from "react-firebase-hooks/firestore";
import AppPageLoading from "../../../components/AppPageLoading";
import { firestore, functions } from "../../../components/FirebaseProvider";
import errorMessageFactory from "../../../utils/errorMessageFactory";

function Upload() {
  //

  const classes = useStyles();

  const [data, load] = useDocument(firestore.doc("news/banner"));
  const [display, setDisplay] = useState(0);

  const [form, setForm] = useState({
    targetUrl: "",
    photoUrl: "",
  });

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const [Load, setLoad] = useState(false);

  const accept = ["image/png", "image/jpeg"];

  const maxSize = "20971520";

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();
  //
  useEffect(() => {
    if (data && !data.empty) {
      setForm((form) => ({ ...form, ...data.data() }));
    }
  }, [data]);
  //
  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  };
  //
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setError();
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onabort = () => {
        setError("Pembacaan File Dibatalkan");
      };
      reader.onerror = () => {
        setError("Pembacaan File Gagal");
      };
      reader.onload = async () => {
        setLoading(true);
        try {
          const generateUploadUrl =
            functions.httpsCallable("generateUploadUrl");

          const result = await generateUploadUrl({
            filename: file.name,
            directory: `images/banner/`,
            contentType: file.type,
          });

          await axios.put(result.data.uploadUrl, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          await firestore.doc(`news/banner`).set(
            {
              photoUrl: result.data.photoUrl,
              status: "banner",
              kategori: "banner",
            },
            { merge: true }
          );

          // Show Notification
          enqueueSnackbar("Banner Berhasil Diupload", { variant: "success" });
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      reader.readAsDataURL(file);
    },
    [enqueueSnackbar]
  );

  const onDropRejected = useCallback(
    (rejected) => {
      if (!accept.includes(rejected[0].type)) {
        setError(`Tipe Tile Tidak Didukung (${rejected[0].type}) `);
      } else if (rejected[0].size >= maxSize) {
        setError(`Ukuran File Terlalu Besar > 20MB`);
      }
    },
    [accept, maxSize]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    maxSize,
    disabled: loading,
  });

  const handleSubmit = async () => {
    setLoad(true);
    try {
      await firestore.doc("news/banner").set(
        {
          targetUrl: form.targetUrl,
        },
        { merge: true }
      );
      // Show Notification
      enqueueSnackbar("Url Berhasil Ditambahkan", { variant: "success" });
    } catch (err) {
      const message = errorMessageFactory(err);
      enqueueSnackbar(message, { variant: "error" });
    }
    setLoad(false);
  };

  if (load || loading === true) {
    return <AppPageLoading />;
  }
  //
  return (
    <div className={classes.Root}>
      <Typography align="left" className={classes.txt}>
        Kelola Banner
      </Typography>
      <div
        className={classes.bannerpreview}
        style={
          form.photoUrl !== ""
            ? {
                background: `url(${form.photoUrl}) no-repeat center  / contain`,
              }
            : null
        }
        {...getRootProps()}
        onMouseOver={() => {
          if (!loading && !load && form.photoUrl !== "") {
            setDisplay(1);
          } else {
            setDisplay(0);
          }
        }}
        onMouseLeave={() => setDisplay(0)}
      >
        <input {...getInputProps()} />
        <label htmlFor="uplod">
          {!load && form.photoUrl === "" ? (
            <Button
              size="large"
              variant="contained"
              color="primary"
              component="span"
              style={{ textTransform: "capitalize" }}
            >
              Pilih Banner &nbsp; {<PhotoCamera />}
            </Button>
          ) : null}
        </label>
        <div style={{ opacity: display }} className={classes.upload}>
          <label htmlFor="uplod">
            <Button
              size="large"
              variant="contained"
              color="primary"
              component="span"
              style={{ textTransform: "capitalize" }}
            >
              Update Banner &nbsp; {<PhotoCamera />}
            </Button>
          </label>
        </div>
      </div>
      {error !== "" ? (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      ) : null}
      <div className={classes.urlInput}>
        <TextField
          placeholder={`${
            load || loading === true
              ? "Loading..."
              : "Masukkan Target Url Yang Ingin Dituju e.x(https://pakbudi.id)"
          }`}
          className={classes.txtUrl}
          value={form.targetUrl}
          name="targetUrl"
          onChange={handleChange}
          variant="outlined"
          disabled={Load}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.btnUrl}
          disabled={Load}
          onClick={handleSubmit}
        >
          Simpan Url
        </Button>
      </div>
    </div>
  );
}

export default Upload;
