export default theme => ({

loadingBody:{
    position: 'absolute',
    top: 170,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    width: 200,
},

logoImg:{
    margin: 'auto',
    width: '100%',
    marginBottom: 15,
},
loadingProgress:{
    width: '100%',
    margin: '0 auto',
},
loadingText:{
    margin: '5px 0 0',
    fontSize: 13,
    fontStyle: 'italic',
},
});