// React
import React, { useEffect, useState } from "react";

//Editor
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Styles
import useStyles from "./styles";

// MUI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";

//Component
import UploadFoto from "./uploadFoto";

//Utils
import axios from "axios";
import { useSnackbar } from "notistack";
import { useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import AppPageLoading from "../../../components/AppPageLoading";
import { useFirebase } from "../../../components/FirebaseProvider";
// import {baseURL} from '../../images/s3';

function NewsDetail(props) {
  const classes = useStyles();

  const params = useParams();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [previewEditorState, setPreviewEditorState] = useState(
    EditorState.createEmpty()
  );

  const { firestore, functions } = useFirebase();
  const docRef = firestore.doc(`news/${params.newsId}`);
  const [snapshot, loading] = useDocument(docRef);

  const queryKategori = firestore.doc("news/kategori");
  const [kategoriSnapshot, kategoriLoading] = useDocument(queryKategori);

  const [form, setForm] = useState({
    kategori: null,
  });

  const [error, setError] = useState({});

  const [isSubmitting, setSubmitting] = useState(false);

  const [isChange, setChange] = useState(false);

  const [kategoriOptions, setKategoriOptions] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleAutocompleteChange = (name) => (e, v) => {
    handleChange({ target: { value: v, name } });
  };

  useEffect(() => {
    if (kategoriSnapshot) {
      setKategoriOptions(
        kategoriSnapshot.data() &&
          kategoriSnapshot.data().items &&
          kategoriSnapshot
            .data()
            .items.map((item) => ({ id: item.id, title: item.title }))
      );
    }
  }, [kategoriSnapshot]);

  useEffect(() => {
    if (snapshot) {
      const data = snapshot.data();
      setForm((currentForm) => ({
        ...currentForm,
        ...data,
      }));
      const contentBlock = htmlToDraft(data.content || "");
      const previewBlock = htmlToDraft(data.preview || "");
      let savedEditorState = EditorState.createEmpty();
      let savedPreviewEditorState = EditorState.createEmpty();
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        savedEditorState = EditorState.createWithContent(contentState);
      }
      if (previewBlock) {
        const previewState = ContentState.createFromBlockArray(
          previewBlock.contentBlocks
        );
        savedPreviewEditorState = EditorState.createWithContent(previewState);
      }
      setEditorState(savedEditorState);
      setPreviewEditorState(savedPreviewEditorState);
    }
  }, [snapshot]);

  const [openDialog, setOpenDialog] = useState(false);

  // Dialog Box
  const handleClickOpen = () => {
    if (isChange !== true) {
      window.history.back();
    } else {
      setOpenDialog(true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleLogout = () => {
    window.history.back();
  };

  const handleChange = (e) => {
    setChange(true);
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    setError({ ...error, content: "" });
    setChange(true);
  };

  const handlePreviewEditorChange = (newEditorState) => {
    setPreviewEditorState(newEditorState);
    setError({ ...error, content: "" });
    setChange(true);
  };

  const uploadImageCallBack = async (file) => {
    try {
      const generateUploadUrl = functions.httpsCallable("generateUploadUrl");

      const result = await generateUploadUrl({
        filename: file.name,
        directory: `images/news/`,
        contentType: file.type,
      });

      await axios.put(result.data.uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      return { data: { link: result.data.photoUrl } };
    } catch {}
  };

  const currentContent = draftToHtml(
    convertToRaw(editorState.getCurrentContent())
  );

  const handleSubmit = (status) => async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      let newData = {
        ...form,
        content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        preview: draftToHtml(
          convertToRaw(previewEditorState.getCurrentContent())
        ),
        updatedAt: Date.now(),
        status,
        pinNews: false,
      };
      if (status === "published") {
        newData.publishedAt = Date.now();
      }

      await docRef.set(newData, { merge: true });
      setChange(false);
      enqueueSnackbar("Berita Berhasil Disimpan", { variant: "success" });
      if (status === "published") {
        enqueueSnackbar("Berita Berhasil Diterbitkan", { variant: "success" });
      }
    } catch (e) {
      const newError = {};

      newError.title = e.message;

      setError(newError);

      enqueueSnackbar(error, { variant: "error" });
    }
    setSubmitting(false);
  };

  if (loading) {
    return <AppPageLoading />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <h1>Edit Berita</h1>
      </div>
      <div className={classes.editorWrap}>
        <TextField
          autoComplete="off"
          disabled={isSubmitting}
          id="title"
          name="title"
          className={classes.textField}
          label="Judul Berita"
          variant="outlined"
          value={form.title || ""}
          onChange={handleChange}
          error={error.title ? true : false}
          helperText={error.title}
        />
        {form?.fee && form.fee > 0 && (
          <Box boxShadow={1} className={classes.formRow}>
            <Typography variant="subtitle2" className={classes.editorTitle}>
              Preview
            </Typography>
            <Editor
              editorState={previewEditorState}
              wrapperClassName="wrapper-class"
              editorClassName={classes.editorBox}
              toolbarClassName={classes.toolbarBox}
              onEditorStateChange={handlePreviewEditorChange}
              toolbar={{
                image: {
                  uploadCallback: uploadImageCallBack,
                  previewImage: true,
                  inputAccept: "image/jpeg, image/jpg, image/png",
                  alt: { present: true, mandatory: true },
                  defaultSize: {
                    height: "auto",
                    width: "100%",
                  },
                },
              }}
            />
            <Typography variant="caption" color="error">
              {error.content}
            </Typography>
          </Box>
        )}
        <Box boxShadow={1} className={classes.formRow}>
          <Typography variant="subtitle2" className={classes.editorTitle}>
            Body
          </Typography>
          <Editor
            editorState={editorState}
            wrapperClassName="wrapper-class"
            editorClassName={classes.editorBox}
            toolbarClassName={classes.toolbarBox}
            onEditorStateChange={handleEditorChange}
            toolbar={{
              image: {
                uploadCallback: uploadImageCallBack,
                previewImage: true,
                inputAccept: "image/jpeg, image/jpg, image/png",
                alt: { present: true, mandatory: true },
                defaultSize: {
                  height: "auto",
                  width: "100%",
                },
              },
            }}
          />
          <Typography variant="caption" color="error">
            {error.content}
          </Typography>
        </Box>
        <TextField
          autoComplete="off"
          disabled={isSubmitting}
          id="fee"
          name="fee"
          className={classes.textField}
          label="Fee"
          variant="outlined"
          value={form.fee || ""}
          onChange={handleChange}
          error={error.fee ? true : false}
          helperText={error.fee}
        />

        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          {/* Thumbnail */}
          <Grid item xs={6}>
            <UploadFoto />
          </Grid>

          {/* Pin & Kategori */}
          <Grid item xs={6}>
            <Grid container spacing={2}>
              {/* Kategori */}
              <Grid item xs={12}>
                <Autocomplete
                  id="category"
                  options={kategoriOptions}
                  getOptionLabel={(option) => option.title}
                  value={form.kategori}
                  onChange={handleAutocompleteChange("kategori")}
                  disableClearable
                  loading={kategoriLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={isSubmitting}
                      className={classes.textField}
                      label="Kategori Berita"
                      variant="outlined"
                      fullWidth
                      helperText={error.kategori}
                      error={error.kategori ? true : false}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off",
                      }}
                    />
                  )}
                />
              </Grid>
              {(form.thumbnailURL === undefined ||
                form.title === undefined ||
                form.kategori === null ||
                currentContent.trim() === "<p></p>") && (
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={2} style={{ fontSize: 35 }}>
                    <ReportProblemOutlinedIcon
                      color="secondary"
                      fontSize="inherit"
                    />
                  </Grid>
                  <Grid item xs={10} style={{ textAlign: "left" }}>
                    <Typography variant="caption">
                      Anda Tidak Bisa Menerbitkan Berita Sebelum Seluruhnya
                      Lengkap
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {/* Button */}

              {/* Save */}
              <Grid item xs={12}>
                {form.status === "published" ? (
                  <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit("published")}
                    name="draft"
                    size="large"
                    color="primary"
                    variant="contained"
                    fullWidth
                  >
                    Simpan & Terbitkan
                  </Button>
                ) : (
                  <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit("draft")}
                    name="draft"
                    size="large"
                    color="primary"
                    variant="contained"
                    fullWidth
                  >
                    Simpan Perubahan
                  </Button>
                )}
              </Grid>
              {/* Publish */}
              {form.status !== "published" && (
                <Grid item xs={12}>
                  <Button
                    disabled={
                      isSubmitting ||
                      form.thumbnailURL === undefined ||
                      form.title === undefined ||
                      form.kategori === null ||
                      form.fee === undefined ||
                      currentContent.trim() === "<p></p>"
                    }
                    name="published"
                    onClick={handleSubmit("published")}
                    size="large"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    className={classes.btnAction}
                  >
                    Terbitkan Berita
                  </Button>
                </Grid>
              )}
              {/* Back */}
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  size="large"
                  color="primary"
                  variant="outlined"
                  fullWidth
                  onClick={handleClickOpen}
                  className={classes.btnAction}
                >
                  Kembali
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* Dialog Box Logout */}

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}
      >
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
            </Grid>
          </Grid>
          <DialogTitle id="alert-dialog-slide-title">
            <Typography variant="body2" style={{ textAlign: "center" }}>
              Perubahan Anda Belum Tersimpan, <br /> Anda Yakin Akan Keluar ?
            </Typography>
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <ButtonGroup fullWidth variant="text">
            <Button onClick={handleClose} color="primary">
              Tidak
            </Button>
            <Button onClick={handleLogout}>Keluar</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NewsDetail;
