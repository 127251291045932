// React
import React, { useEffect, useState } from "react";

//Editor
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Styles
import useStyles from "./styles";

// MUI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";

//Component
import UploadFoto from "./uploadFoto";

//Utils
import axios from "axios";
import { useSnackbar } from "notistack";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import AppPageLoading from "../../../components/AppPageLoading";
import { firestore, functions } from "../../../components/FirebaseProvider";
import errorMessageFactory from "../../../utils/errorMessageFactory";

function EditQuestions(props) {
  const classes = useStyles();

  const params = useParams();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editor2State, setEditor2State] = useState(EditorState.createEmpty());
  const docRef = firestore.doc(`soal/${params.soalId}`);
  const docKunci = firestore.doc(`kunci_jawaban/${params.soalId}`);
  const [snapshot, loading] = useDocument(docRef);
  const [kunciSnapshot, kunciLoading] = useDocument(docKunci);

  const queryKategori = firestore.collection("kategori_soal");
  const queryTryout = firestore.collection(`tryout`);
  const [kategoriSnapshot, kategoriLoading] = useCollection(queryKategori);
  const [tryoutSnapshot, tryoutLoading] = useCollection(queryTryout);

  const [form, setForm] = useState({
    kategori_soal: null,
    tryout: null,
    kode_soal: "",
  });

  const [pilihan, setPilihan] = useState({
    a: {
      text: "",
      type: "",
    },
    b: {
      text: "",
      type: "",
    },
    c: {
      text: "",
      type: "",
    },
    d: {
      text: "",
      type: "",
    },
    e: {
      text: "",
      type: "",
    },
  });

  const [jawaban, setJawaban] = useState("");
  const [error, setError] = useState({});

  const [isSubmitting, setSubmitting] = useState(false);

  const [isChange, setChange] = useState(false);

  const [kategoriOptions, setKategoriOptions] = useState([]);
  const [tryoutOptions, setTryoutOptions] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleAutocompleteChange = (name) => (e, v) => {
    handleChange({ target: { value: v, name } });
  };

  useEffect(() => {
    if (
      kategoriSnapshot &&
      tryoutSnapshot &&
      !kategoriSnapshot.empty &&
      !tryoutSnapshot.empty
    ) {
      setKategoriOptions(
        kategoriSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            nama: doc.data().title,
            createdAt: doc.data().createdAt,
          };
        })
      );
      setTryoutOptions(
        tryoutSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            nama: doc.data().judul,
          };
        })
      );
    }
  }, [kategoriSnapshot, tryoutSnapshot]);

  useEffect(() => {
    if (snapshot && kunciSnapshot) {
      const data = snapshot.data();
      const kunci = kunciSnapshot.data();
      setForm((currentForm) => ({
        ...currentForm,
        ...data,
      }));
      setPilihan((pilihan) => ({
        ...pilihan,
        ...data.pilihan_jawaban,
      }));
      setJawaban(kunci.jawaban);

      const contentBlock = htmlToDraft(data.pertanyaan || "");
      let savedEditorState = EditorState.createEmpty();
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        savedEditorState = EditorState.createWithContent(contentState);
      }
      setEditorState(savedEditorState);

      const content2Block = htmlToDraft(kunci.penjelasan || "");
      let savedEditor2State = EditorState.createEmpty();

      if (content2Block) {
        const content2State = ContentState.createFromBlockArray(
          content2Block.contentBlocks
        );
        savedEditor2State = EditorState.createWithContent(content2State);
      }

      setEditor2State(savedEditor2State);
    }
  }, [kunciSnapshot, snapshot]);

  const [openDialog, setOpenDialog] = useState(false);

  // Dialog Box
  const handleClickOpen = () => {
    if (isChange !== true) {
      window.history.back();
    } else {
      setOpenDialog(true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleLogout = () => {
    window.history.back();
  };

  const handleChange = (e) => {
    setChange(true);

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const handleChangePilihan = (e) => {
    setChange(true);

    setPilihan({
      ...pilihan,
      [e.target.name]: {
        text: e.target.value,
        url: "",
        type: "text",
      },
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const handleChangeJawaban = (e) => {
    setChange(true);

    setJawaban(e.target.value);

    setError({
      ...error,
      jawaban: "",
    });
  };

  const handleCancel = (id) => {
    setChange(true);

    setPilihan({
      ...pilihan,
      [id]: {
        text: "",
        url: "",
        type: "",
      },
    });

    setError({
      ...error,
      [id]: "",
    });
  };

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    setError({ ...error, content: "" });
    setChange(true);
  };

  const handleEditor2Change = (newEditorState) => {
    setEditor2State(newEditorState);
    setError({ ...error, penjelasan: "" });
    setChange(true);
  };

  const uploadImageCallBack = async (file) => {
    try {
      const generateUploadUrl = functions.httpsCallable("generateUploadUrl");

      const result = await generateUploadUrl({
        filename: file.name,
        directory: `images/tryout/`,
        contentType: file.type,
      });

      await axios.put(result.data.uploadUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      return { data: { link: result.data.photoUrl } };
    } catch {}
  };

  const validate = async () => {
    const newError = { ...error };
    const { kategori_soal, tryout } = form;
    const { a, b, c, d, e } = pilihan;

    const currentContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    if (!tryout) {
      newError.tryout = "Pilih Kategori Tryout";
    }

    if (currentContent.trim() === "<p></p>") {
      newError.content = "Tulis Konten Soal";
    }

    if (!jawaban) {
      newError.jawaban = "Jawaban Benar Wajib Di isi";
    }

    if (!a.type) {
      newError.a = "Pilihan Jawaban A Wajib Di isi";
    }

    if (!b.type) {
      newError.b = "Pilihan Jawaban B Wajib Di isi";
    }

    if (!c.type) {
      newError.c = "Pilihan Jawaban C Wajib Di isi";
    }

    if (!d.type) {
      newError.d = "Pilihan Jawaban D Wajib Di isi";
    }

    if (!e.type) {
      newError.e = "Pilihan Jawaban E Wajib Di isi";
    }

    if (!kategori_soal) {
      newError.kategori_soal = "Pilih Kategori Soal";
    }

    return newError;
  };

  const handleSubmit = (status) => async (e) => {
    e.preventDefault();

    const findError = await validate();

    const errorMessages = Object.values(findError);
    if (errorMessages.some((m) => m !== "")) {
      setError(findError);
      const main = document.getElementById("main-content");

      main.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      for (const message of errorMessages) {
        if (message) enqueueSnackbar(message, { variant: "error" });
      }
    } else {
      setSubmitting(true);

      try {
        const { kategori_soal, tryout, kode_soal } = { ...form };
        const { a, b, c, d, e } = { ...pilihan };
        let newData = {
          kategori_soal,
          tryout,
          pilihan_jawaban: { a, b, c, d, e },
          pertanyaan: draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          ),
          updatedAt: Date.now(),
        };

        if (kode_soal) {
          newData = {
            kategori_soal,
            kode_soal,
            tryout,
            pilihan_jawaban: { a, b, c, d, e },
            pertanyaan: draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            ),
            updatedAt: Date.now(),
          };
        }
        //
        if (kategori_soal.id !== snapshot.data().kategori_soal.id) {
          const queryTryout = firestore.doc(
            `tryout/${snapshot.data().tryout.id}`
          );
          await firestore.runTransaction((transaction) => {
            return transaction.get(queryTryout).then((doc) => {
              let curr_jumlah_soal_perkategori =
                doc.data().jumlah_soal_perkategori || [];
              let jumlah_soal_perkategori = [];
              if (!Array.isArray(curr_jumlah_soal_perkategori)) {
                curr_jumlah_soal_perkategori = Object.entries(
                  curr_jumlah_soal_perkategori
                ).map((item) => {
                  const [key, val] = item;

                  return {
                    kategori: key,
                    ...val,
                  };
                });
              }

              const findKategori = curr_jumlah_soal_perkategori.find(
                (c) => c.kategori === kategori_soal.id
              );

              if (findKategori) {
                jumlah_soal_perkategori = curr_jumlah_soal_perkategori.map(
                  (c) => {
                    if (c.kategori === kategori_soal.id) {
                      return {
                        ...c,
                        total: c.total + 1,
                        nama: kategori_soal.nama,
                      };
                    }

                    return c;
                  }
                );
              } else {
                jumlah_soal_perkategori = [
                  ...curr_jumlah_soal_perkategori,
                  {
                    kategori: kategori_soal.id,
                    nama: kategori_soal.nama,
                    total: 1,
                  },
                ];
              }

              const findOldKategori = jumlah_soal_perkategori.find(
                (c) => c.kategori === snapshot.data().kategori_soal.id
              );

              if (findOldKategori) {
                jumlah_soal_perkategori = jumlah_soal_perkategori.map((c) => {
                  if (c.kategori === snapshot.data().kategori_soal.id) {
                    return {
                      ...c,
                      total: c.total - 1,
                    };
                  }

                  return c;
                });
              }

              transaction.update(queryTryout, {
                jumlah_soal_perkategori,
              });
              // return jumlah_soal;
            });
          });
          // await firestore.doc(`tryout/${snapshot.data().tryout.id}`).set({
          //     jumlah_soal_perkategori: {
          //         [snapshot.data().kategori_soal.id]: {
          //             total: Arr.increment(-1),
          //         },
          //         [kategori_soal.id]: {
          //             total: Arr.increment(1),
          //         }
          //     },
          // }, { merge: true })
        } else {
          const queryTryout = firestore.doc(
            `tryout/${snapshot.data().tryout.id}`
          );
          await firestore.runTransaction((transaction) => {
            return transaction.get(queryTryout).then((doc) => {
              let curr_jumlah_soal_perkategori =
                doc.data().jumlah_soal_perkategori || [];
              let jumlah_soal_perkategori = [];
              if (!Array.isArray(curr_jumlah_soal_perkategori)) {
                curr_jumlah_soal_perkategori = Object.entries(
                  curr_jumlah_soal_perkategori
                ).map((item) => {
                  const [key, val] = item;

                  return {
                    kategori: key,
                    ...val,
                  };
                });
              }

              jumlah_soal_perkategori = [...curr_jumlah_soal_perkategori];

              transaction.update(queryTryout, {
                jumlah_soal_perkategori,
              });
              // return jumlah_soal;
            });
          });
        }
        //
        await docRef.set(newData, { merge: true });
        await firestore.doc(`kunci_jawaban/${params.soalId}`).set(
          {
            jawaban: jawaban,
            penjelasan: draftToHtml(
              convertToRaw(editor2State.getCurrentContent())
            ),
          },
          { merge: true }
        );
        setChange(false);
        enqueueSnackbar("Soal Berhasil Diperbarui & Diterbitkan", {
          variant: "success",
        });
        window.history.back();
      } catch (e) {
        const message = errorMessageFactory(e);
        enqueueSnackbar(message, { variant: "error" });
      }
      setSubmitting(false);
    }
  };

  if (loading || kunciLoading) {
    return <AppPageLoading />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <h1>Edit Soal</h1>
      </div>
      <div className={classes.editorWrap}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              id="tryout"
              options={tryoutOptions}
              getOptionLabel={(option) => option.nama}
              value={form.tryout}
              onChange={handleAutocompleteChange("tryout")}
              disableClearable
              loading={tryoutLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={isSubmitting}
                  className={classes.textField}
                  label="Kategori Tryout"
                  variant="outlined"
                  fullWidth
                  helperText={error.tryout}
                  error={error.tryout ? true : false}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id="category"
              options={kategoriOptions}
              getOptionLabel={(option) => option.nama}
              value={form.kategori_soal}
              onChange={handleAutocompleteChange("kategori_soal")}
              disableClearable
              loading={kategoriLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={isSubmitting}
                  className={classes.textField}
                  label="Kategori Soal"
                  variant="outlined"
                  fullWidth
                  helperText={error.kategori_soal}
                  error={error.kategori_soal ? true : false}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="kode_soal"
              className={classes.textField}
              label="Kode Soal"
              variant="outlined"
              fullWidth
              autoComplete="off"
              disabled={isSubmitting}
              value={form.kode_soal}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Box boxShadow={1} className={classes.formRow}>
          <Editor
            editorState={editorState}
            wrapperClassName="wrapper-class"
            editorClassName={classes.editorBox}
            toolbarClassName={classes.toolbarBox}
            onEditorStateChange={handleEditorChange}
            toolbar={{
              image: {
                uploadCallback: uploadImageCallBack,
                previewImage: true,
                inputAccept: "image/jpeg, image/jpg, image/png",
                alt: { present: true, mandatory: true },
                defaultSize: {
                  height: "auto",
                  width: "100%",
                },
              },
            }}
          />
          <Typography variant="caption" color="error">
            {error.content}
          </Typography>
        </Box>

        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  style={{ display: "flex" }}
                  alignItems=""
                  justifyContent="space-between"
                >
                  <Typography align="center">Pilihan Jawaban</Typography>
                  <Typography align="center">
                    Jawaban
                    <br />
                    Benar
                  </Typography>
                </Grid>
                <RadioGroup
                  aria-label="jawaban"
                  name="jawaban1"
                  value={jawaban}
                  onChange={handleChangeJawaban}
                >
                  <Grid
                    style={{ display: "flex" }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {pilihan.a.type === "image" ? (
                      <div className={classes.imgjawaban}>
                        <img width="100%" alt="" src={pilihan.a.url} />
                      </div>
                    ) : (
                      <TextField
                        label="Pilihan A"
                        variant="outlined"
                        name="a"
                        fullWidth
                        className={classes.pilihan}
                        value={pilihan.a.text}
                        onChange={handleChangePilihan}
                        error={error.a ? true : false}
                        helperText={error.a}
                        disabled={isSubmitting}
                        autoComplete="off"
                      />
                    )}
                    <Grid className={classes.upload1}>
                      {pilihan.a.type === "image" ? (
                        <IconButton onClick={() => handleCancel("a")}>
                          <CancelIcon color="error" />
                        </IconButton>
                      ) : (
                        <UploadFoto
                          mode="a"
                          pilihan={pilihan}
                          setData={setPilihan}
                          setErr={setError}
                          Err={error}
                        />
                      )}
                      <FormControlLabel value="a" control={<Radio />} />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ display: "flex" }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {pilihan.b.type === "image" ? (
                      <div className={classes.imgjawaban}>
                        <img width="100%" alt="" src={pilihan.b.url} />
                      </div>
                    ) : (
                      <TextField
                        label="Pilihan B"
                        variant="outlined"
                        name="b"
                        fullWidth
                        className={classes.pilihan}
                        value={pilihan.b.text}
                        onChange={handleChangePilihan}
                        error={error.b ? true : false}
                        helperText={error.b}
                        disabled={isSubmitting}
                        autoComplete="off"
                      />
                    )}
                    <Grid className={classes.upload1}>
                      {pilihan.b.type === "image" ? (
                        <IconButton onClick={() => handleCancel("b")}>
                          <CancelIcon color="error" />
                        </IconButton>
                      ) : (
                        <UploadFoto
                          mode="b"
                          pilihan={pilihan}
                          setData={setPilihan}
                          setErr={setError}
                          Err={error}
                        />
                      )}
                      <FormControlLabel value="b" control={<Radio />} />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ display: "flex" }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {pilihan.c.type === "image" ? (
                      <div className={classes.imgjawaban}>
                        <img width="100%" alt="" src={pilihan.c.url} />
                      </div>
                    ) : (
                      <TextField
                        label="Pilihan C"
                        variant="outlined"
                        name="c"
                        fullWidth
                        className={classes.pilihan}
                        value={pilihan.c.text}
                        onChange={handleChangePilihan}
                        error={error.c ? true : false}
                        helperText={error.c}
                        disabled={isSubmitting}
                        autoComplete="off"
                      />
                    )}
                    <Grid className={classes.upload1}>
                      {pilihan.c.type === "image" ? (
                        <IconButton onClick={() => handleCancel("c")}>
                          <CancelIcon color="error" />
                        </IconButton>
                      ) : (
                        <UploadFoto
                          mode="c"
                          pilihan={pilihan}
                          setData={setPilihan}
                          setErr={setError}
                          Err={error}
                        />
                      )}
                      <FormControlLabel value="c" control={<Radio />} />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ display: "flex" }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {pilihan.d.type === "image" ? (
                      <div className={classes.imgjawaban}>
                        <img width="100%" alt="" src={pilihan.d.url} />
                      </div>
                    ) : (
                      <TextField
                        label="Pilihan D"
                        variant="outlined"
                        name="d"
                        fullWidth
                        className={classes.pilihan}
                        value={pilihan.d.text}
                        onChange={handleChangePilihan}
                        error={error.d ? true : false}
                        helperText={error.d}
                        disabled={isSubmitting}
                        autoComplete="off"
                      />
                    )}
                    <Grid className={classes.upload1}>
                      {pilihan.d.type === "image" ? (
                        <IconButton onClick={() => handleCancel("d")}>
                          <CancelIcon color="error" />
                        </IconButton>
                      ) : (
                        <UploadFoto
                          mode="d"
                          pilihan={pilihan}
                          setData={setPilihan}
                          setErr={setError}
                          Err={error}
                        />
                      )}
                      <FormControlLabel value="d" control={<Radio />} />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ display: "flex" }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {pilihan.e.type === "image" ? (
                      <div className={classes.imgjawaban}>
                        <img width="100%" alt="" src={pilihan.e.url} />
                      </div>
                    ) : (
                      <TextField
                        label="Pilihan E"
                        variant="outlined"
                        name="e"
                        fullWidth
                        className={classes.pilihan}
                        value={pilihan.e.text}
                        onChange={handleChangePilihan}
                        error={error.e ? true : false}
                        helperText={error.e}
                        disabled={isSubmitting}
                        autoComplete="off"
                      />
                    )}
                    <Grid className={classes.upload1}>
                      {pilihan.e.type === "image" ? (
                        <IconButton onClick={() => handleCancel("e")}>
                          <CancelIcon color="error" />
                        </IconButton>
                      ) : (
                        <UploadFoto
                          mode="e"
                          pilihan={pilihan}
                          setData={setPilihan}
                          setErr={setError}
                          Err={error}
                        />
                      )}
                      <FormControlLabel value="e" control={<Radio />} />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {error.jawaban ? (
                  <Typography align="center" color="error">
                    {error.jawaban}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit("published")}
                  name="draft"
                  size="large"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Perbarui & Terbitkan
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  size="large"
                  color="primary"
                  variant="outlined"
                  fullWidth
                  onClick={handleClickOpen}
                  className={classes.btnAction}
                >
                  Kembali
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container spacing={3} marginTop={10}>
                    <Grid item xs={12}>
                        <Typography align='left'>Penjelasan</Typography>
                    </Grid>
                </Grid> */}
        <Typography align="left" style={{ marginTop: 50 }}>
          Penjelasan
        </Typography>
        <Box boxShadow={1} className={classes.formRow}>
          <Editor
            editorState={editor2State}
            wrapperClassName="wrapper-class"
            editorClassName={classes.editorBox}
            toolbarClassName={classes.toolbarBox}
            onEditorStateChange={handleEditor2Change}
            toolbar={{
              image: {
                uploadCallback: uploadImageCallBack,
                previewImage: true,
                inputAccept: "image/jpeg, image/jpg, image/png",
                alt: { present: true, mandatory: true },
                defaultSize: {
                  height: "auto",
                  width: "100%",
                },
              },
            }}
          />
          <Typography variant="caption" color="error">
            {error.penjelasan}
          </Typography>
        </Box>
      </div>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}
      >
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
            </Grid>
          </Grid>
          <DialogTitle id="alert-dialog-slide-title">
            <Typography variant="body2" style={{ textAlign: "center" }}>
              Perubahan Anda Belum Tersimpan, <br /> Anda Yakin Akan Keluar ?
            </Typography>
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <ButtonGroup fullWidth variant="text">
            <Button onClick={handleClose} color="primary">
              Tidak
            </Button>
            <Button onClick={handleLogout}>Keluar</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditQuestions;
