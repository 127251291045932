const errorMessageFactory = (error) => {
  let message;
  if (error?.response) {
    message = error?.response?.data?.message;
  } else if (error?.request) {
    message =
      "Something went wrong with your request, check your internet connection";
  } else {
    message = error.message;
  }
  return message;
};

export default errorMessageFactory;
