import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  dataCon: {
    textAlign: "center",
    backgroundColor: "#1abc9c",
    color: "white",
    borderRadius: 5,
    marginBottom: 50,
  },

  cardText: {
    fontWeight: 400,
    fontSize: 11,
    marginBottom: 35,
    color: "#5e5e5e",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },

  pageHeader: {
    marginTop: 40,
    marginBottom: 15,
  },

  mainLogo: {
    marginTop: -10,
  },

  pageTitle: {
    color: "#5e5e5e",
    marginBottom: -15,
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },

  cardColor1: {
    background: "linear-gradient(to right, #3096ec, #30bcec)",
    color: "white",
  },

  cardColor2: {
    background: "linear-gradient(to right, #ff4e50, #f9d423)",
    // opacity: 0.5,
    color: "white",
    cursor: "context-menu",
  },

  cardC: {
    marginTop: 30,
  },

  cardI: {
    padding: 10,
  },

  text1a: {
    fontSize: 16,
    textAlign: "left",
    fontWeight: 600,
  },

  text1b: {
    fontSize: 12,
    textAlign: "left",
    marginBottom: 9,
  },

  text1c: {
    fontSize: 25,
    textAlign: "left",
    fontWeight: 600,
  },

  link: {
    color: "white",
    textDecoration: "none",
  },

  btn: {
    color: "white",
    border: "1px solid white",
    marginTop: 20,
  },

  gridContainer: {
    display: "grid",
    gridGap: 10,
  },

  gridContainerRatio: {
    "display": "grid",
    "gridGap": 10,
    // for screens smaller than 768px
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr",
    },
    // for screens between 768px and 1024px (including iPad)
    "@media (min-width: 768px) and (max-width: 1024px)": {
      gridTemplateColumns: "2fr 1fr",
      gridGap: 5,
    },
    // for screens larger than 1024px
    "@media (min-width: 1024px)": {
      gridTemplateColumns: "3fr 1fr",
      gridGap: 10,
    },
  },

  gridContainerColumn3: {
    "display": "grid",
    "gridGap": 10,
    // for screens smaller than 768px
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr",
    },
    // for screens larger than 768px
    "@media (min-width: 768px)": {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },

  gridContent: {
    "padding": "10px",
    "position": "relative",
    "textAlign": "left",
    // for screens smaller than 768px
    "@media (max-width: 768px)": {
      fontSize: "16px",
    },
    // for screens larger than 768px
    "@media (min-width: 768px)": {
      fontSize: "18px",
    },
  },

  button: {
    display: "inline-block",
    cursor: "pointer",
    padding: "10px",
    minWidth: "100px",
    textAlign: "center",
    border: "1px solid white",
    color: "white",
    textDecoration: "none",
    verticalAlign: "middle",
  },
}));

export default useStyles;
