import React, { useState } from "react";

//Styles
import styles from "./styles/index";

//Utils
import { Redirect } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { useFirebase } from "../../components/FirebaseProvider";

//MUI
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import EmailIcon from "@material-ui/icons/Email";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

//Components
import AppLoading from "../../components/AppLoading";
import mainLogo from "../../images/logo3.svg";

function Login(props) {
  const { classes, location } = props;
  const { loading, user, auth } = useFirebase();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const validate = () => {
    const newError = { ...error };
    const { email, password } = form;

    if (!email) {
      newError.email = "Email wajib di isi";
    } else if (!isEmail(email)) {
      newError.email = "Email tidak valid";
    }

    if (!password) {
      newError.password = "Password wajib di isi";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        await auth.signInWithEmailAndPassword(form.email, form.password);
      } catch (e) {
        const newError = {};

        switch (e.code) {
          case "auth/user-not-found":
            newError.email = "Email tidak terdaftar";
            break;
          case "auth/invalid-email":
            newError.email = "Email tidak valid";
            break;
          case "auth/wrong-password":
            newError.password = "Password salah";
            break;
          case "auth/user-disabled":
            newError.email = "Pengguna di blokir";
            break;
          default:
            newError.email = "Terjadi kesalahan, silahkan coba lagi";
            break;
        }

        setError(newError);
        setSubmitting(false);
      }
    }
  };

  if (loading) {
    return <AppLoading />;
  }

  if (user) {
    const redirectTo =
      location.state && location.state.from && location.state.from.pathname
        ? location.state.from.pathname
        : "/home";
    return <Redirect to={redirectTo} />;
  }

  return (
    <Container maxWidth="sm" className={classes.loginContainer}>
      <div className={classes.loginBlock}>
        <img className={classes.mainLogo} src={mainLogo} alt="" width="180vw" />
        <Typography className={classes.pageTitle}>
          Selamat Datang, Admin !
        </Typography>
        <Typography className={classes.pageSubtitle}>
          Gunakan Akun Yang Sudah Tersedia
        </Typography>
        <form onSubmit={handleSubmit}>
          <div className={classes.formRow}>
            <TextField
              variant="outlined"
              autoComplete="off"
              disabled={isSubmitting}
              id="email"
              name="email"
              placeholder="Masukkan Email"
              className={classes.textField}
              value={form.email}
              onChange={handleChange}
              helperText={error.email}
              error={error.email ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon className={classes.iconColor} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.formRow}>
            <TextField
              autoComplete="off"
              variant="outlined"
              disabled={isSubmitting}
              id="password"
              name="password"
              placeholder="Masukkan Password"
              className={classes.textField}
              type="password"
              value={form.password}
              onChange={handleChange}
              helperText={error.password}
              error={error.password ? true : false}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKeyIcon className={classes.iconColor} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.formRow}>
            <Button
              disabled={isSubmitting}
              className={classes.loginBtn}
              variant="contained"
              color="primary"
              type="submit"
            >
              Masuk
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
}

export default withStyles(styles)(Login);
