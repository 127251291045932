// React
import React, { useCallback, useEffect, useState } from "react";

//
import useStyles from "./styles";

//
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

// Utils
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import AppPageLoading from "../../../components/AppPageLoading";
import { firestore, functions } from "../../../components/FirebaseProvider";
import errorMessageFactory from "../../../utils/errorMessageFactory";

function Upload() {
  //

  const classes = useStyles();

  const [data, load] = useDocument(firestore.doc("events/banner"));
  const [display, setDisplay] = useState(0);
  const [display2, setDisplay2] = useState(0);
  const [form, setForm] = useState({
    target_event: {},
    photoUrl: "",
    tmbPhotoUrl: "",
    tmb_target_event: {},
  });

  const queryEvents = firestore
    .collection("events")
    .where("status", "==", "published");
  const [error, setError] = useState("");
  const [errorTmb, setErrorTmb] = useState("");
  const [events, loadingEvents] = useCollectionData(queryEvents, {
    idField: "id",
  });
  const [loading, setLoading] = useState(false);

  const [Load, setLoad] = useState(false);

  const accept = ["image/png", "image/jpeg", "image/gif"];

  const maxSize = "20971520";
  // Snackbar
  const { enqueueSnackbar } = useSnackbar();
  //
  useEffect(() => {
    if (data && !data.empty) {
      setForm((form) => ({ ...form, ...data.data() }));
    }
  }, [data]);
  //
  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));
  };

  const handleAutocompleteChange = (name) => (e, v) => {
    handleChange({ target: { value: v, name } });
  };

  //
  const onDropAccepted = useCallback(
    (acceptedFiles, e) => {
      const mode = e.target.id;
      setError();
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onabort = () => {
        setError("Pembacaan File Dibatalkan");
      };
      reader.onerror = () => {
        setError("Pembacaan File Gagal");
      };
      reader.onload = async () => {
        setLoading(true);
        try {
          const generateUploadUrl =
            functions.httpsCallable("generateUploadUrl");

          const result = await generateUploadUrl({
            filename: file.name,
            directory: `images/banner/`,
            contentType: file.type,
          });

          await axios.put(result.data.uploadUrl, file, {
            headers: {
              "Content-Type": file.type,
            },
          });
          let fieldPhoto = "photoUrl";
          if (mode === "tmb") {
            fieldPhoto = "tmbPhotoUrl";
          }

          await firestore.doc(`events/banner`).set(
            {
              [fieldPhoto]: result.data.photoUrl,
              status: "banner",
              kategori: "banner",
            },
            { merge: true }
          );

          // Show Notification
          enqueueSnackbar("Banner Berhasil Diupload", { variant: "success" });
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      reader.readAsDataURL(file);
    },
    [enqueueSnackbar]
  );

  const onDropRejected = useCallback(
    (rejected, e) => {
      const mode = e.target.id;
      if (!accept.includes(rejected[0].type)) {
        if (mode === "tmb")
          setErrorTmb(`Tipe Tile Tidak Didukung (${rejected[0].type}) `);
        else setError(`Tipe Tile Tidak Didukung (${rejected[0].type}) `);
      } else if (rejected[0].size >= maxSize) {
        if (mode === "tmb") setErrorTmb(`Ukuran File Terlalu Besar > 20MB`);
        else setError(`Ukuran File Terlalu Besar > 20MB`);
      }
    },
    [accept, maxSize]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    maxSize,
    disabled: loading,
  });
  const { getRootProps: getRootPropsTmb, getInputProps: getInputPropsTmb } =
    useDropzone({
      onDropAccepted,
      onDropRejected,
      accept,
      maxSize,
      disabled: loading,
    });

  const handleSubmit = (name) => async () => {
    setLoad(true);
    try {
      await firestore.doc("events/banner").set(
        {
          [name]: form.target_event,
        },
        { merge: true }
      );
      // Show Notification
      enqueueSnackbar("Target Event Berhasil Ditambahkan", {
        variant: "success",
      });
    } catch (err) {
      const message = errorMessageFactory(err);
      enqueueSnackbar(message, { variant: "error" });
    }
    setLoad(false);
  };

  if (load || loading === true) {
    return <AppPageLoading />;
  }

  //
  return (
    <div className={classes.Root}>
      <Typography align="left" className={classes.txt}>
        Kelola Banner (Home)
      </Typography>
      <div
        className={classes.bannerpreview}
        style={
          form.photoUrl !== ""
            ? { background: `url(${form.photoUrl}) no-repeat center / contain` }
            : null
        }
        {...getRootProps()}
        onMouseOver={() => {
          if (!loading && !load) {
            setDisplay(1);
          } else {
            setDisplay(0);
          }
        }}
        onMouseLeave={() => setDisplay(0)}
      >
        <input
          {...getInputProps({
            id: "home",
          })}
        />
        <div style={{ opacity: display }} className={classes.upload}>
          <label>
            {!load && !form.photoUrl ? (
              <Button
                size="large"
                variant="contained"
                color="primary"
                component="span"
                style={{ textTransform: "capitalize" }}
              >
                Pilih Banner &nbsp; {<PhotoCamera />}
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                color="primary"
                component="span"
                style={{ textTransform: "capitalize" }}
              >
                Update Banner &nbsp; {<PhotoCamera />}
              </Button>
            )}
          </label>
        </div>
      </div>
      {error !== "" ? (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      ) : null}
      <div className={classes.urlInput}>
        {/* <TextField
                    placeholder={`${load || loading === true ? 'Loading...' : "Masukkan Target Url Yang Ingin Dituju e.x(https://pakbudi.id)"}`}
                    className={classes.txtUrl}
                    value={form.targetUrl}
                    name="targetUrl"
                    onChange={handleChange}
                    variant="outlined"
                    disabled={Load}
                /> */}
        <Autocomplete
          id="target_event"
          options={events || []}
          getOptionLabel={(option) => option.nama}
          value={form.target_event}
          onChange={handleAutocompleteChange("target_event")}
          disableClearable
          loading={loadingEvents}
          style={{ flex: 1 }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                disabled={Load}
                className={classes.textField}
                label="Target Event"
                variant="outlined"
                fullWidth
                helperText={error}
                error={error ? true : false}
                inputProps={{
                  ...params.inputProps,
                  value: form.target_event?.nama,
                  autoComplete: "off",
                }}
              />
            );
          }}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.btnUrl}
          disabled={Load}
          onClick={handleSubmit("target_event")}
          size="large"
        >
          Simpan Target Event
        </Button>
      </div>

      <Typography align="left" className={classes.txt}>
        Kelola Banner (TMB)
      </Typography>
      <div
        className={classes.bannerpreview}
        style={
          form.tmbPhotoUrl !== ""
            ? {
                background: `url(${form.tmbPhotoUrl}) no-repeat center  / contain`,
              }
            : null
        }
        {...getRootPropsTmb()}
        onMouseOver={() => {
          if (!loading && !load) {
            setDisplay2(1);
          } else {
            setDisplay2(0);
          }
        }}
        onMouseLeave={() => setDisplay2(0)}
      >
        <input {...getInputPropsTmb({ id: "tmb" })} />
        <div style={{ opacity: display2 }} className={classes.upload}>
          <label>
            {!load && !form.tmbPhotoUrl ? (
              <Button
                size="large"
                variant="contained"
                color="primary"
                component="span"
                style={{ textTransform: "capitalize" }}
              >
                Pilih Banner &nbsp; {<PhotoCamera />}
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                color="primary"
                component="span"
                style={{ textTransform: "capitalize" }}
              >
                Update Banner &nbsp; {<PhotoCamera />}
              </Button>
            )}
          </label>
        </div>
        {/* <div
                    style={{ opacity: display2 }}
                    className={classes.upload}
                >
                    
                    <label htmlFor="tmb">
                        <Button size="large" variant="contained" color="primary" component="span" style={{textTransform: 'capitalize'}}>
                            Update Banner &nbsp; {<PhotoCamera />}
                        </Button>
                    </label>
                </div> */}
      </div>
      {errorTmb !== "" ? (
        <Typography variant="caption" color="error">
          {errorTmb}
        </Typography>
      ) : null}
      <div className={classes.urlInput}>
        {/* <TextField
                    placeholder={`${load || loading === true ? 'Loading...' : "Masukkan Target Url Yang Ingin Dituju e.x(https://pakbudi.id)"}`}
                    className={classes.txtUrl}
                    value={form.targetUrl}
                    name="targetUrl"
                    onChange={handleChange}
                    variant="outlined"
                    disabled={Load}
                /> */}
        <Autocomplete
          id="tmb_target_event"
          options={events || []}
          getOptionLabel={(option) => option.nama}
          value={form.tmb_target_event}
          onChange={handleAutocompleteChange("tmb_target_event")}
          disableClearable
          loading={loadingEvents}
          style={{ flex: 1 }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                disabled={Load}
                className={classes.textField}
                label="Target Event"
                variant="outlined"
                fullWidth
                helperText={errorTmb}
                error={errorTmb ? true : false}
                inputProps={{
                  ...params.inputProps,
                  value: form.tmb_target_event?.nama,
                  autoComplete: "off",
                }}
              />
            );
          }}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.btnUrl}
          disabled={Load}
          onClick={handleSubmit("tmb_target_event")}
          size="large"
        >
          Simpan Target Event
        </Button>
      </div>
    </div>
  );
}

export default Upload;
