import React, { useEffect, useState } from "react";

// MUI
import MaterialTable from "@material-table/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

// Styles
// import useStyles from './styles';

// Component
import AppPageLoading from "../../../../components/AppPageLoading";
import SelectKota from "../../../../components/AppSelect/kota";
import SelectProvinsi from "../../../../components/AppSelect/provinsi";

// Utils
import update from "immutability-helper";
import { useSnackbar } from "notistack";
import { Arr, firestore } from "../../../../components/FirebaseProvider";
import errorMessageFactory from "../../../../utils/errorMessageFactory";
import getTableTitle from "../../../../utils/getTableTitle";

function Sekolah() {
  // const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [filter, setFilter] = useState({
    provinsi: {
      nama: "",
      provinsi_id: "",
    },
    kota: {
      nama: "",
      kota_id: "",
    },
  });

  const handleClear = () => {
    setFilter({
      provinsi: {
        nama: "",
        provinsi_id: "",
      },
      kota: {
        nama: "",
        kota_id: "",
      },
    });
  };

  const [loading, setLoading] = useState(false);

  const [table, setTable] = React.useState({
    columns: [
      {
        title: getTableTitle("ID Sekolah"),
        field: "sekolah_id",
        editable: "never",
      },
      { title: getTableTitle("Nama Sekolah"), field: "nama" },
    ],
    data: [],
  });

  useEffect(() => {
    if (filter.kota.kota_id) {
      const querySekolah = async () => {
        setLoading(true);
        const snapshot = await firestore
          .collection("sekolah")
          .where("kota_id", "==", filter.kota.kota_id)
          .get();

        setTable((table) => {
          return {
            ...table,
            data: snapshot.docs.map((doc) => {
              return {
                uid: doc.id,
                ...doc.data(),
              };
            }),
          };
        });
        setLoading(false);
      };
      querySekolah();
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [filter.kota.kota_id]);

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilter((form) => ({
      ...form,
      [name]: value,
    }));
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ margin: 10 }}
      >
        <Grid item xs={4}>
          <SelectProvinsi
            value={{
              label: filter.provinsi.nama,
              value: filter.provinsi.provinsi_id,
            }}
            onChange={({ value, label }) => {
              handleChangeFilter({
                target: {
                  value: {
                    nama: label,
                    provinsi_id: value,
                  },
                  name: "provinsi",
                },
              });
              handleChangeFilter({
                target: {
                  value: {
                    nama: "",
                    kota_id: "",
                  },
                  name: "kota",
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectKota
            value={{ label: filter.kota.nama, value: filter.kota.kota_id }}
            onChange={({ value, label }) => {
              handleChangeFilter({
                target: {
                  value: {
                    nama: label,
                    kota_id: value,
                  },
                  name: "kota",
                },
              });
            }}
            provinsi_id={filter.provinsi.provinsi_id}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleClear}>
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>

      {loading ? (
        <AppPageLoading />
      ) : table.data.length > 0 ? (
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            style={{ marginBottom: 10 }}
          >
            <Grid item style={{ fontSize: 35 }}>
              <ReportProblemOutlinedIcon color="secondary" fontSize="inherit" />
            </Grid>
            <Grid item>
              <Typography align="left" variant="caption">
                Saat Anda Menambah Data Baru, Data Sekolah Akan Diambil Dari
                Pilihan Diatas.
              </Typography>
              <br />
              <Typography align="left" variant="caption">
                Pastikan Pilihan Anda Sesuai Dengan Data Yang Akan Ditambahkan
              </Typography>
            </Grid>
          </Grid>

          <MaterialTable
            options={{ thirdSortClick: false }}
            title="Data Sekolah"
            columns={table.columns}
            data={table.data}
            editable={{
              onRowAdd: async (newData) => {
                try {
                  if (!newData.nama) {
                    enqueueSnackbar("Nama Sekolah Wajib Diisi", {
                      variant: "error",
                    });

                    throw new Error("Nama Sekolah Wajib Diisi");
                  }
                  const counterSnap = await firestore
                    .doc("counters/sekolah")
                    .get();
                  const counterData = counterSnap.data();
                  const newColData = {
                    ...newData,
                    sekolah_id: counterData.count + 1,
                    provinsi_id: filter.provinsi.provinsi_id,
                    kota_id: filter.kota.kota_id,
                  };
                  const newSekolah = await firestore
                    .collection("sekolah")
                    .add(newColData);
                  await firestore
                    .doc("counters/sekolah")
                    .set({ count: Arr.increment(1) }, { merge: true });

                  setTable((table) => {
                    return {
                      ...table,
                      data: [
                        { ...newColData, uid: newSekolah.id },
                        ...table.data,
                      ],
                    };
                  });
                  enqueueSnackbar("Data Berhasil Ditambahkan", {
                    variant: "success",
                  });
                } catch (e) {
                  const message = errorMessageFactory(e);
                  enqueueSnackbar(message, { variant: "error" });
                }
              },

              onRowDelete: async (oldData) => {
                await firestore.doc(`sekolah/${oldData.uid}`).delete();
                setTable((table) => {
                  return {
                    ...table,
                    data: update(table.data, {
                      $splice: [[oldData.tableData.id, 1]],
                    }),
                  };
                });
                enqueueSnackbar("Data Berhasil Dihapus", {
                  variant: "success",
                });
              },

              onRowUpdate: async (newData, oldData) => {
                if (!newData.nama) {
                  enqueueSnackbar("Nama Sekolah Wajib Diisi", {
                    variant: "error",
                  });

                  throw new Error("Nama Sekolah Wajib Diisi");
                }

                const newColData = {
                  kota_id: newData.kota_id,
                  nama: newData.nama,
                  provinsi_id: newData.provinsi_id,
                  sekolah_id: newData.sekolah_id,
                };

                await firestore
                  .doc(`sekolah/${oldData.uid}`)
                  .set(newColData, { merge: true });
                setTable((table) => {
                  return {
                    ...table,
                    data: update(table.data, {
                      [oldData.tableData.id]: { $set: newData },
                    }),
                  };
                });
                enqueueSnackbar("Data Berhasil Diubah", { variant: "success" });
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Tidak Ada Data",
                addTooltip: "Tambah",
                editTooltip: "Ubah",
                deleteTooltip: "Hapus",
                editRow: {
                  deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
                  cancelTooltip: "Batal",
                  saveTooltip: "Ya",
                },
              },
              toolbar: {
                searchTooltip: "Cari",
                searchPlaceholder: "Cari Data",
              },
              header: {
                actions: "Tindakan",
              },
              pagination: {
                labelRowsSelect: "Baris",
                labelDisplayedRows: " {from}-{to} Dari {count} Baris",
                firstTooltip: "Halaman Pertama",
                previousTooltip: "Halaman Sebelumnya",
                nextTooltip: "Halaman Selanjutnya",
                lastTooltip: "Halaman Terakhir",
              },
            }}
          />
        </>
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ margin: "10% 0", paddingBottom: "7.5%" }}
        >
          <Grid item style={{ fontSize: 40 }}>
            <InfoOutlinedIcon htmlColor="grey" fontSize="inherit" />
          </Grid>
          <Grid item>
            <Typography>
              Silahkan Pilih Provinsi Dan Kota Terlebih Dahulu
            </Typography>
            <Typography variant="caption">
              Data Akan Tampil Sesuai Provinsi dan Kota Yang Dipilih
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Sekolah;
