import React, { useEffect, useState } from "react";

import { useCollection } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";

import MaterialTable from "@material-table/core";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";

import AppPageLoading from "../../../components/AppPageLoading";
import SelectKota from "../../../components/AppSelect/kota";
import SelectProvinsi from "../../../components/AppSelect/provinsi";
import SelectSekolah from "../../../components/AppSelect/sekolah";

import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
// import AppPageLoading from '../../../components/AppPageLoading';
import { useSnackbar } from "notistack";

// import { formatDate } from '../../../utils/formatter';

import Dialog from "./dialog";

import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import getTableTitle from "../../../utils/getTableTitle";
import useStyles from "./styles";

function PendaftarITTP() {
  const { enqueueSnackbar } = useSnackbar();

  const [filter, setFilter] = useState({
    provinsi: {
      nama: "",
      provinsi_id: "",
    },
    kota: {
      nama: "",
      kota_id: "",
    },
    sekolah: {
      nama: "",
      sekolah_id: "",
    },
  });

  const [table, setTable] = useState({
    columns: [
      { title: getTableTitle("Nama"), field: "nama" },
      { title: getTableTitle("Jurusan Sekolah"), field: "jurusan_sekolah" },
      { title: getTableTitle("Prodi"), field: "program_studi" },
      { title: getTableTitle("Jalur Daftar"), field: "jalurPendaftaran_ittp" },
      { title: getTableTitle("Beasiswa"), field: "beasiswa" },
      { title: getTableTitle("Status Fee"), field: "status" },
      {
        title: getTableTitle("Fee Guru BK"),
        field: "fee",
        render: (rowData) => <span>Rp. {rowData.fee}</span>,
      },
    ],
    data: [],
  });

  let query = null;
  let length = "Noop";
  if (filter.sekolah.nama !== "") {
    const querySiswa = firestore.collection("pendaftar_ittp");
    query = querySiswa.doc(`${filter.sekolah.sekolah_id}`).collection("siswa");
    length = table.data.length <= 0 ? true : false;
  }
  const [snapshot, loading] = useCollection(query);

  const classes = useStyles();

  const [dialog, setDialog] = useState({
    mode: "",
    open: false,
    data: {},
  });

  useEffect(() => {
    if (snapshot && !snapshot.empty) {
      setTable((table) => {
        return {
          ...table,
          data: snapshot.docs.map((doc) => {
            return {
              uid: doc.id,
              ...doc.data(),
            };
          }),
        };
      });
    } else {
      setTable((table) => {
        return {
          ...table,
          data: [],
        };
      });
    }
  }, [snapshot]);

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilter((form) => ({
      ...form,
      [name]: value,
    }));
  };

  const handleClear = () => {
    setFilter({
      provinsi: {
        nama: "",
        provinsi_id: "",
      },
      kota: {
        nama: "",
        kota_id: "",
      },
      sekolah: {
        nama: "",
        sekolah_id: "",
      },
    });
  };

  return (
    <Paper style={{ paddingTop: 20 }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ margin: 10 }}
      >
        <Grid item xs={3}>
          <SelectProvinsi
            value={{
              label: filter.provinsi.nama,
              value: filter.provinsi.provinsi_id,
            }}
            onChange={({ value, label }) => {
              handleChangeFilter({
                target: {
                  value: {
                    nama: label,
                    provinsi_id: value,
                  },
                  name: "provinsi",
                },
              });
              handleChangeFilter({
                target: {
                  value: {
                    nama: "",
                    kota_id: "",
                  },
                  name: "kota",
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectKota
            value={{ label: filter.kota.nama, value: filter.kota.kota_id }}
            onChange={({ value, label }) => {
              handleChangeFilter({
                target: {
                  value: {
                    nama: label,
                    kota_id: value,
                  },
                  name: "kota",
                },
              });
              handleChangeFilter({
                target: {
                  value: {
                    nama: "",
                    sekolah_id: "",
                  },
                  name: "sekolah",
                },
              });
            }}
            provinsi_id={filter.provinsi.provinsi_id}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectSekolah
            value={{
              label: filter.sekolah.nama,
              value: filter.sekolah.sekolah_id,
            }}
            onChange={({ value, label }) => {
              handleChangeFilter({
                target: {
                  value: {
                    nama: label,
                    sekolah_id: value,
                  },
                  name: "sekolah",
                },
              });
            }}
            provinsi_id={filter.provinsi.provinsi_id}
            kota_id={filter.kota.kota_id}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleClear}>
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>
      {length === "Undefined" && null}
      {(length === true) & !loading ? (
        <Typography style={{ margin: "12% 0", paddingBottom: "12%" }}>
          Tidak Ada Data
        </Typography>
      ) : null}
      {!filter.sekolah.sekolah_id ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ margin: "10% 0", paddingBottom: "7.5%" }}
        >
          <Grid item style={{ fontSize: 40 }}>
            <InfoOutlinedIcon htmlColor="grey" fontSize="inherit" />
          </Grid>
          <Grid item>
            <Typography>
              Silahkan Pilih Provinsi, Kota, Dan Sekolah Terlebih Dahulu
            </Typography>
            <Typography variant="caption">
              Data Akan Tampil Sesuai Provinsi, Kota, Dan Sekolah Yang Dipilih
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      {loading && <AppPageLoading />}
      {table.data.length > 0 && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            style={{ marginBottom: 10 }}
          >
            <Grid item style={{ fontSize: 35 }}>
              <ReportProblemOutlinedIcon color="secondary" fontSize="inherit" />
            </Grid>
            <Grid item style={{ textAlign: "left" }}>
              <Typography variant="caption">
                Saat Anda Menambah Data Baru, Data Sekolah Akan Diambil Dari
                Pilihan Diatas.
              </Typography>
              <br />
              <Typography variant="caption">
                Pastikan Pilihan Anda Sesuai Dengan Data Yang Akan Ditambahkan
              </Typography>
            </Grid>
          </Grid>
          <MaterialTable
            options={{ thirdSortClick: false }}
            title="Data Pendaftar ITTP"
            columns={table.columns}
            data={table.data}
            components={{
              Container: (props) => <div {...props} elevation={0} />,
            }}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit",
                onClick: (event, data) => {
                  setDialog({
                    mode: "Edit",
                    data,
                    sekolah: filter.sekolah.sekolah_id,
                    open: true,
                  });
                },
              },
            ]}
            editable={{
              onRowDelete: async (data) => {
                await firestore
                  .collection("pendaftar_ittp")
                  .doc(`${filter.sekolah.sekolah_id}`)
                  .collection("siswa")
                  .doc(`${data.uid}`)
                  .delete();
                enqueueSnackbar("Data Berhasil Dihapus", {
                  variant: "success",
                });
              },
            }}
            localization={{
              body: {
                emptyDataSourceMessage: "Tidak Ada Data",
                addTooltip: "Tambah",
                editTooltip: "Ubah",
                deleteTooltip: "Hapus",
                editRow: {
                  deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
                  cancelTooltip: "Batal",
                  saveTooltip: "Ya",
                },
              },
              toolbar: {
                searchTooltip: "Cari",
                searchPlaceholder: "Cari Data",
              },
              header: {
                actions: "Tindakan",
              },
              pagination: {
                labelRowsSelect: "Baris",
                labelDisplayedRows: " {from}-{to} Dari {count} Baris",
                firstTooltip: "Halaman Pertama",
                previousTooltip: "Halaman Sebelumnya",
                nextTooltip: "Halaman Selanjutnya",
                lastTooltip: "Halaman Terakhir",
              },
            }}
          />
        </>
      )}
      {filter.sekolah.sekolah_id && (
        <Fab
          className={classes.fab}
          color="primary"
          onClick={(e) => {
            setDialog({
              mode: "Tambah",
              open: true,
              data: {},
              sekolah: filter.sekolah.sekolah_id,
            });
          }}
        >
          <PersonAddOutlinedIcon />
        </Fab>
      )}
      <Dialog
        dialog={dialog}
        handleClose={() => {
          setDialog({ mode: "", open: false, data: {} });
        }}
      />
    </Paper>
  );
}

export default PendaftarITTP;
