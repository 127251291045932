import { Paper } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
import Tabel from "./tabel";

function Migration() {
  const classes = useStyles();
  return (
    <Paper square>
      <div className={classes.tabContent}>
        <Tabel />
      </div>
    </Paper>
  );
}

export default Migration;
