export default {

    direction: 'ltr',

    palette: {
        primary: {
            main: '#30bcec',
            light: '',
            dark: '#0e5f96',
            contrastText: '#fff',
        },
        secondary: {
            main: '#fdb31b',
            light: '',
            dark: '',
            contrastText: '#000',
        },

        textColor: {
            gray: '#777',
            gray2: '#ddd',
        }
    },

    typography: {
        useNextVariants: true,
    }
}