import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

const MigrateDialog = ({ open, handleClose, isLoading, handleMigrate }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Migrate Data ke SQL</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Apakah anda yakin ingin migrasi data yang anda pilih ke SQL? jika ya,
          silahkan tekan tombol Migrasi. Jika tidak, silahkan tekan tombol
          kembali.
        </DialogContentText>
        {isLoading && (
          <DialogContentText>Sedang menyiapkan data...</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={handleClose}
          style={{
            color: "#e62120",
          }}
          variant="outlined"
        >
          Kembali
        </Button>
        <Button
          fullWidth
          disabled={isLoading === true}
          onClick={handleMigrate}
          color="primary"
          autoFocus
          variant="contained"
        >
          Migrasi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MigrateDialog;
