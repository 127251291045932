import React, { useEffect, useMemo, useState } from "react";

import { useParams } from "react-router-dom";

import { useCollection } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";

import MaterialTable from "@material-table/core";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import AppPageLoading from "../../../components/AppPageLoading";
import { formatDate, unixToDate } from "../../../utils/formatter";
import { currency } from "../../../utils/formatter-rupiah";
import getTableTitle from "../../../utils/getTableTitle";
import Dialog from "./dialog";

function Tabel() {
  const { status } = useParams();

  const queryNews = firestore
    .collection("transaksi_event")
    .where("status", "==", status);

  const [snapshot, loading] = useCollection(queryNews);

  const [dialog, setDialog] = useState({
    open: false,
    data: [],
  });

  const title = {
    review: "Transaksi Ditinjau",
    konfirmasi: "Transaksi Dalam Proses",
    sukses: "Transaksi Dikonfirmasi",
    rejected: "Transaksi Diitemlak",
  };

  const sortdate = useMemo(() => {
    switch (status) {
      case "review":
        return "reviewed_at";
      case "sukses":
        return "approved_at";
      case "rejected":
        return "rejected_at";
      default:
        return "created_at";
    }
  }, [status]);

  const [table, setTable] = useState({
    columns: [
      {
        title: getTableTitle("Kode Daftar"),
        field: "kode_daftar",
        render: (rowData) => (
          <>
            {rowData && rowData.kode_daftar !== undefined ? (
              <span>{rowData.kode_daftar}</span>
            ) : (
              <span>-</span>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Nama"),
        field: "nama_lengkap",
        render: (rowData) => (
          <>
            {rowData && rowData.nama_lengkap !== undefined ? (
              <span>{rowData.nama_lengkap}</span>
            ) : (
              <span>Tidak Ada Nama</span>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Email"),
        field: "email",
        render: (rowData) => (
          <>
            {rowData && rowData.email !== undefined ? (
              <span>{rowData.email}</span>
            ) : (
              <span>Tidak Ada Email</span>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Tanggal"),
        defaultSort: "desc",
        field: sortdate,
        render: (rowData) => {
          if (rowData.status === "review") {
            return <span>{formatDate(rowData.reviewed_at?.toMillis?.())}</span>;
          } else if (rowData.status === "sukses") {
            return <span>{formatDate(rowData.approved_at?.toMillis?.())}</span>;
          } else if (rowData.status === "rejected") {
            return <span>{formatDate(rowData.rejected_at?.toMillis?.())}</span>;
          }

          return <span>{formatDate(rowData.created_at)}</span>;
        },
      },
      { title: getTableTitle("Lokasi Event"), field: "lokasi_event.name" },
      {
        title: getTableTitle("Total Pembayaran"),
        field: "total_pembayaran",
        render: (rowData) => {
          return <span>{currency(rowData.total_pembayaran || 0)}</span>;
        },
      },
    ],
    data: [],
  });

  useEffect(() => {
    if (snapshot) {
      setTable((table) => {
        return {
          ...table,
          columns: table.columns.map((col) => {
            if (col.title === "Tanggal") {
              return {
                ...col,
                field: sortdate,
              };
            }
            return col;
          }),
          data: snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              uid: doc.data().doc_id,
              ...doc.data(),
            };
          }),
        };
      });
    }
  }, [snapshot, sortdate]);

  if (loading) {
    return <AppPageLoading />;
  }

  return (
    <>
      <MaterialTable
        title={title[status]}
        columns={table.columns}
        data={table.data}
        actions={
          status === "review"
            ? [
                {
                  itemoltip: "Detail",
                  icon: () => (
                    <Tooltip title="Lihat Transaksi">
                      <VisibilityIcon />
                    </Tooltip>
                  ),
                  onClick: (event, data) => {
                    setDialog({
                      open: true,
                      data,
                    });
                  },
                },
              ]
            : []
        }
        options={{
          thirdSortClick: false,
          exportButton: {
            csv: true,
          },
          exportCsv: (columns, data) => {
            const csvData = Papa.unparse(
              data.map((item) => {
                if (item?.status === "sukses") {
                  return {
                    "Transaksi ID": item?.event_transaksi_id ?? "",
                    "Event ID": item?.event_id ?? "",
                    "Kode Daftar": item?.kode_daftar ?? "",
                    "Nama Lengkap": item?.nama_lengkap ?? "",
                    "No Whatsaap": item?.no_wa ?? "",
                    "No Telepon": item?.no_telepon ?? "",
                    Email: item?.email ?? "",
                    "Lokasi Event ID": item?.lokasi_event?.id ?? "",
                    "Lokasi Event": item?.lokasi_event?.name ?? "",
                    "Jenis Tryout": item?.jenis_tryout ?? "",
                    "Total Pembayaran": currency(item?.total_pembayaran) ?? "",
                    "Dibuat Pada": unixToDate(item?.created_at ?? 0) ?? "",
                  };
                }

                return {
                  "Transaksi ID": item?.event_transaksi_id ?? "",
                  "Event ID": item?.event_id ?? "",
                  "Nama Lengkap": item?.nama_lengkap ?? "",
                  "No Whatsaap": item?.no_wa ?? "",
                  "No Telepon": item?.no_telepon ?? "",
                  Email: item?.email ?? "",
                  "Lokasi Event ID": item?.lokasi_event?.id ?? "",
                  "Lokasi Event": item?.lokasi_event?.name ?? "",
                  "Jenis Tryout": item?.jenis_tryout ?? "",
                  "Total Pembayaran": currency(item?.total_pembayaran) ?? "",
                  "Dibuat Pada": unixToDate(item?.created_at ?? 0) ?? "",
                };
              })
            );

            const csvBlob = new Blob([csvData], {
              type: "text/plain;charset=utf-8",
            });

            saveAs(csvBlob, `${title[status]}.csv`);
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: `Tidak Ada ${title[status]}`,
            additemoltip: "Tambah",
            edititemoltip: "Ubah",
            deleteitemoltip: "Hapus",
            editRow: {
              deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
              cancelitemoltip: "Batal",
              saveitemoltip: "Ya",
            },
          },
          itemolbar: {
            searchitemoltip: "Cari",
            searchPlaceholder: "Cari Data",
          },
          header: {
            actions: "Tindakan",
          },
          pagination: {
            labelRowsSelect: "Baris",
            labelDisplayedRows: " {from}-{to} Dari {count} Baris",
            firstitemoltip: "Halaman Pertama",
            previousitemoltip: "Halaman Sebelumnya",
            nextitemoltip: "Halaman Selanjutnya",
            lastitemoltip: "Halaman Terakhir",
          },
        }}
      />

      <Dialog
        dialog={dialog}
        handleClose={() => {
          setDialog((dialog) => {
            return {
              ...dialog,
              open: false,
            };
          });
        }}
      />
    </>
  );
}

export default Tabel;
