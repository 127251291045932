import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import lokasiUjian from "./lokasi-ujian.json";
// utils
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { Arr } from "../../../components/FirebaseProvider";
import useStyles from "./styles";

function DialogPeserta({
  dialog: { mode, open, data },
  handleClose,
  pesertaRef,
}) {
  const classes = useStyles();

  const [form, setForm] = useState({
    nama_lengkap: "",
    email: "",
    no_wa: "",
  });

  const [error, setError] = useState({
    nama_lengkap: "",
    email: "",
    no_wa: "",
  });
  useEffect(() => {
    const defaultData = {
      nama_lengkap: "",
      email: "",
      no_wa: "",
    };

    const defaultError = {
      nama_lengkap: "",
      email: "",
      no_wa: "",
    };

    if (mode === "Tambah") {
      setForm(defaultData);
      setError(defaultError);
    } else if (mode === "Edit" || mode === "View") {
      setForm(data);
      setError(defaultError);
    }
  }, [data, mode]);

  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setError((error) => ({
      ...error,
      [name]: "",
    }));
  };

  const handleAutocompleteChange = (name) => (e, v) => {
    handleChange({ target: { value: v, name } });
  };
  const validate = async () => {
    const newError = { ...error };
    if (!form.nama_lengkap) {
      newError.nama_lengkap = "Nama Lengkap wajib diisi.";
    }

    if (!form.no_wa) {
      newError.no_wa = "No Whatsaap wajib diisi.";
    }

    if (!form.no_telepon) {
      newError.no_telepon = "No Telepon wajib diisi.";
    }

    if (!form.email) {
      newError.email = "Email wajib diisi.";
    }

    if (!form.lokasi_event) {
      newError.lokasi_event = "Lokasi event wajib diisi.";
    }

    if (!form.jenis_tryout) {
      newError.jenis_tryout = "Jenis Tryout wajib diisi.";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        if (mode === "Tambah") {
          await pesertaRef.add({
            ...form,
            created_at: Arr.serverTimestamp(),
            updated_at: Arr.serverTimestamp(),
          });
          handleClose();
          enqueueSnackbar("Peserta berhasil ditambahkan", {
            variant: "success",
          });
        } else if (mode === "Edit") {
          const { uid, tableData, ...restForm } = form;
          await pesertaRef.doc(uid).update({
            ...restForm,
            updated_at: Arr.serverTimestamp(),
          });
          handleClose();
          enqueueSnackbar("Peserta Behasil Diperbarui", { variant: "success" });
        }
      } catch (e) {
        const newError = {};

        newError.nilai_koin = e.message;

        setError(newError);
      }
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
    >
      <DialogTitle>{mode} Peserta Event</DialogTitle>
      <DialogContent dividers>
        {mode === "View" ? (
          <Grid container>
            <Grid item xs={4}>
              <Typography>ID Peserta</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>: {form.pesertaId}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Nama Lengkap</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>: {form.nama_lengkap}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>No Whatsaap</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                :{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://wa.me/${form.no_wa}`}
                >
                  {form.no_wa}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>No Telp.</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>: {form.no_telepon}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>E-mail</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>: {form.email}</Typography>
            </Grid>{" "}
            <Grid item xs={4}>
              <Typography>Lokasi Event</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>: {form.lokasi_event?.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Jenis Tryout</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>: {form.jenis_tryout}</Typography>
            </Grid>
          </Grid>
        ) : (
          <form id="create-admin-form" onSubmit={handleSubmit}>
            <TextField
              className={classes.marginTextfield}
              disabled={isSubmitting}
              id="nama_lengkap"
              name="nama_lengkap"
              label="Nama Lengkap"
              fullWidth
              autoComplete="off"
              value={form.nama_lengkap}
              onChange={handleChange}
              helperText={error.nama_lengkap}
              error={error.nama_lengkap ? true : false}
            />
            <TextField
              className={classes.marginTextfield}
              disabled={isSubmitting}
              id="no_telepon"
              name="no_telepon"
              label="No Telp."
              fullWidth
              autoComplete="off"
              value={form.no_telepon}
              onChange={handleChange}
              helperText={error.no_telepon}
              error={error.no_telepon ? true : false}
            />
            <TextField
              className={classes.marginTextfield}
              disabled={isSubmitting}
              id="no_wa"
              name="no_wa"
              label="No Whatsapp"
              fullWidth
              autoComplete="off"
              value={form.no_wa}
              onChange={handleChange}
              helperText={error.no_wa}
              error={error.no_wa ? true : false}
            />
            <TextField
              className={classes.marginTextfield}
              disabled={isSubmitting}
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="off"
              value={form.email}
              onChange={handleChange}
              helperText={error.email}
              error={error.email ? true : false}
            />

            <Autocomplete
              id="lokasi_event"
              options={lokasiUjian}
              getOptionLabel={(option) => option.name}
              value={form.lokasi_event}
              onChange={handleAutocompleteChange("lokasi_event")}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={isSubmitting}
                  className={classes.textField}
                  label="Pilih Lokasi Event"
                  fullWidth
                  helperText={error.lokasi_event}
                  error={error.lokasi_event ? true : false}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                />
              )}
            />

            <Autocomplete
              id="jenis_tryout"
              options={["SOSHUM", "SAINTEK"]}
              getOptionLabel={(option) => option}
              value={form.jenis_tryout}
              onChange={handleAutocompleteChange("jenis_tryout")}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  disabled={isSubmitting}
                  className={classes.textField}
                  label="Jenis Tryout"
                  fullWidth
                  helperText={error.jenis_tryout}
                  error={error.jenis_tryout ? true : false}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "off",
                  }}
                />
              )}
            />
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          Batal
        </Button>
        {mode !== "View" && (
          <Button
            form="create-admin-form"
            disabled={isSubmitting}
            type="submit"
            color="primary"
          >
            Simpan
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

DialogPeserta.propTypes = {
  dialog: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withRouter(DialogPeserta);
