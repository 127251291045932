// React
import React, { useCallback, useEffect, useState } from "react";

// Utils
import axios from "axios";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import { useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { firestore, functions } from "../../../components/FirebaseProvider";

// Styles
import useStyles from "./styles";

// MUI
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

function UploadFoto({ data }) {
  const classes = useStyles();

  const params = useParams();

  const [photo, setPhoto] = useState();

  let doc = "";
  if (data && data.uid) {
    const Doc = firestore.doc(`pencairan_mitra/${data.uid}`);
    const DocBk = firestore.doc(`pencairan_bk/${data.uid}`);
    if (params.status === "mitra") {
      doc = Doc;
    }
    if (params.status === "bk") {
      doc = DocBk;
    }
  }

  const [snapshot, load] = useDocument(doc);

  useEffect(() => {
    if (snapshot) {
      setPhoto({
        ...snapshot.data(),
      });
    }
  }, [snapshot]);

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const accept = ["image/png", "image/jpeg"];

  const maxSize = "20971520";

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setError();
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onabort = () => {
        setError("Pembacaan File Dibatalkan");
      };
      reader.onerror = () => {
        setError("Pembacaan File Gagal");
      };
      reader.onload = async () => {
        setLoading(true);
        try {
          const generateUploadUrl =
            functions.httpsCallable("generateUploadUrl");

          const result = await generateUploadUrl({
            filename: file.name,
            directory: `images/buktiPencairan/`,
            contentType: file.type,
          });

          await axios.put(result.data.uploadUrl, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          await doc.set(
            {
              photoUrl: result.data.photoUrl,
            },
            { merge: true }
          );

          // Show Notification
          enqueueSnackbar("Bukti Berhasil Ditambahkan", { variant: "success" });
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      reader.readAsDataURL(file);
    },
    [doc, enqueueSnackbar]
  );

  const onDropRejected = useCallback(
    (rejected) => {
      if (!accept.includes(rejected[0].type)) {
        setError(`Tipe Tile Tidak Didukung (${rejected[0].type}) `);
      } else if (rejected[0].size >= maxSize) {
        setError(`Ukuran File Terlalu Besar > 20MB`);
      }
    },
    [accept, maxSize]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    maxSize,
    disabled: loading,
  });
  return (
    <>
      <Card {...getRootProps()} className={classes.avatarChange}>
        <div>
          <Grid
            container
            justifyContent="center"
            spacing={1}
            className={classes.thumbLayout}
          >
            {/* Button */}
            <Grid item xs={12} className={classes.thumbMB}>
              <Typography
                variant="body1"
                align="left"
                className={classes.thumbMB}
              >
                {" "}
                Bukti Transfer{" "}
              </Typography>
              <input {...getInputProps()} />
              <label htmlFor="uplod">
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  component="span"
                >
                  Pilih File
                </Button>
              </label>
            </Grid>
            {/* Preview */}
            {load && <Typography>Loading...</Typography>}
            {photo && photo.photoUrl ? (
              <Grid item xs={12} className={classes.thumbPLayout}>
                <img
                  className={classes.thumbPreview}
                  src={photo.photoUrl}
                  alt=""
                />
              </Grid>
            ) : (
              <Typography
                style={{ margin: 30 }}
                variant="caption"
                color="secondary"
              >
                Belum Ada Gambar. Gambar Akan Tampil Disini Jika Tersedia
              </Typography>
            )}
            {error && (
              <Typography color="error" variant="caption" align="center">
                {error}
              </Typography>
            )}
          </Grid>
        </div>
      </Card>
    </>
  );
}

export default UploadFoto;
