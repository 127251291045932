import React from "react";

//material-ui
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

import { Switch, Route, Redirect } from "react-router-dom";
// komponen halaman pengguna
import TO from "./TO";
import NewStudent from "./newStudent";
import Misi from "./misi";

function Chart(props) {
  const { location, history } = props;
  const handleChangeTab = (event, value) => {
    history.push(value);
  };

  return (
    <Paper square style={{ marginBottom: 50 }}>
      <Tabs
        centered
        variant="fullWidth"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeTab}
      >
        <Tab label="Chart New Student" value="/chart/newStudent" />
        <Tab label="Chart TO" value="/chart/TO" />
        <Tab label="Chart Misi" value="/chart/misi" />
      </Tabs>
      <Divider />
      <div>
        <Switch>
          <Route path="/chart/newStudent" component={NewStudent} />
          <Route path="/chart/TO" component={TO} />
          <Route path="/chart/misi" component={Misi} />
          <Redirect to="/chart/newStudent" />
        </Switch>
      </div>
    </Paper>
  );
}

export default Chart;
