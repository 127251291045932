import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";
import RpFormat from "../../../components/NumberFormat";

// utils
import { withRouter } from "react-router-dom";
import { Arr, firestore } from "../../../components/FirebaseProvider";
import useStyles from "./styles";

function AddDialog({ dialog: { mode, open, data, sekolah }, handleClose }) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState({
    nama: "",
    jurusan_sekolah: "",
    program_studi: "",
    jalurPendaftaran_ittp: "",
    beasiswa: "",
    fee: "",
    status: "",
  });

  const [error, setError] = useState({
    nama: "",
    jurusan_sekolah: "",
    program_studi: "",
    jalurPendaftaran_ittp: "",
    beasiswa: "",
    status: "",
  });

  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    const defaultData = {
      nama: "",
      jurusan_sekolah: "",
      program_studi: "",
      jalurPendaftaran_ittp: "",
      beasiswa: "",
      fee: "",
      status: "",
    };
    const defaulError = {
      nama: "",
      jurusan_sekolah: "",
      program_studi: "",
      jalurPendaftaran_ittp: "",
      beasiswa: "",
      status: "",
    };
    if (mode === "Tambah") {
      setForm(defaultData);
      setError(defaulError);
    } else if (mode === "Edit") {
      setForm(data);
      setError(defaulError);
    }
  }, [data, mode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));
    setError((error) => ({
      ...error,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };
    const {
      nama,
      jurusan_sekolah,
      program_studi,
      jalurPendaftaran_ittp,
      beasiswa,
      status,
    } = form;

    if (!nama) {
      newError.nama = "Nama Wajib Diisi";
    }

    if (!jurusan_sekolah) {
      newError.jurusan_sekolah = "Jurusan Sekolah Wajib Diisi";
    }

    if (!program_studi) {
      newError.program_studi = "Program Studi Wajib Diisi";
    }

    if (!jalurPendaftaran_ittp) {
      newError.jalurPendaftaran_ittp = "Jalur Pendaftaran Wajib Diisi";
    }

    if (!beasiswa) {
      newError.beasiswa = "Beasiswa Wajib Diisi";
    }

    if (!status) {
      newError.status = "Status Wajib Diisi";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const FindErrors = validate();

    const {
      nama,
      status,
      fee,
      jalurPendaftaran_ittp,
      program_studi,
      jurusan_sekolah,
      beasiswa,
    } = form;

    if (Object.values(FindErrors).some((err) => err !== "")) {
      setError(FindErrors);
    } else {
      setSubmitting(true);
      try {
        if (mode === "Tambah") {
          await firestore.collection("pendaftar_ittp").doc(`${sekolah}`).set(
            {
              createdAt: Arr.serverTimestamp(),
            },
            { merge: true }
          );
          await firestore
            .doc(`pendaftar_ittp/${sekolah}`)
            .collection("siswa")
            .add({
              nama: nama.replace(/\w\S*/g, (txt) => {
                return (
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
              }),
              status: status,
              fee: fee,
              jalurPendaftaran_ittp: jalurPendaftaran_ittp,
              program_studi: program_studi,
              jurusan_sekolah: jurusan_sekolah,
              beasiswa: beasiswa,
            });
          enqueueSnackbar("Data Berhasil Ditambahkan", { variant: "success" });
        } else if (mode === "Edit") {
          const { uid } = form;
          await firestore.collection("pendaftar_ittp").doc(`${sekolah}`).set(
            {
              updatedAt: Arr.serverTimestamp(),
            },
            { merge: true }
          );
          await firestore
            .doc(`pendaftar_ittp/${sekolah}`)
            .collection(`siswa`)
            .doc(`${uid}`)
            .set(
              {
                nama: nama.replace(/\w\S*/g, (txt) => {
                  return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  );
                }),
                status: status,
                fee: fee,
                jalurPendaftaran_ittp: jalurPendaftaran_ittp,
                program_studi: program_studi,
                jurusan_sekolah: jurusan_sekolah,
                beasiswa: beasiswa,
              },
              { merge: true }
            );
          enqueueSnackbar("Data Berhasil Diubah", { variant: "success" });
        }
        handleClose();
      } catch (error) {
        setSubmitting(false);
        if (mode === "Tambah") {
          enqueueSnackbar("Data Gagal Ditambahkan", { variant: "error" });
        } else if (mode === "Edit") {
          enqueueSnackbar("Data Gagal Diubah", { variant: "error" });
        }
      }
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{mode} Data Pendaftar</DialogTitle>
      <DialogContent dividers>
        <form id="create-admin-form">
          <TextField
            className={classes.marginTextfield}
            disabled={isSubmitting}
            id="nama"
            name="nama"
            label="Nama"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            autoComplete="off"
            value={form.nama}
            onChange={handleChange}
            helperText={error.nama}
            error={error.nama ? true : false}
          />
          <FormControl
            fullWidth
            className={classes.marginTextfield}
            error={error.jurusan_sekolah ? true : false}
          >
            <InputLabel id="demo-simple-select-label">
              Jurusan Sekolah
            </InputLabel>
            <Select
              name="jurusan_sekolah"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.jurusan_sekolah}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={"IPA"}>IPA</MenuItem>
              <MenuItem value={"IPS"}>IPS</MenuItem>
              <MenuItem value={"BAHASA"}>BAHASA</MenuItem>
              <MenuItem value={"AGAMA"}>AGAMA</MenuItem>
              <MenuItem value={"TEKNIK"}>TEKNIK</MenuItem>
              <MenuItem value={"NON TEKNIK"}>NON TEKNIK</MenuItem>
            </Select>
            <FormHelperText>{error.jurusan_sekolah}</FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            className={classes.marginTextfield}
            error={error.program_studi ? true : false}
          >
            <InputLabel id="demo-simple-select-label">Program Studi</InputLabel>
            <Select
              name="program_studi"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.program_studi}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={"D3 Teknik Telekomunikasi"}>
                D3 Teknik Telekomunikasi
              </MenuItem>
              <MenuItem value={"S1 Teknik Telekomunikasi"}>
                S1 Teknik Telekomunikasi
              </MenuItem>
              <MenuItem value={"S1 Teknik Elektro"}>S1 Teknik Elektro</MenuItem>
              <MenuItem value={"S1 Teknik Informatika"}>
                S1 Teknik Informatika
              </MenuItem>
              <MenuItem value={"S1 Sistem Informasi"}>
                S1 Sistem Informasi
              </MenuItem>
              <MenuItem value={"S1 Software Engineering"}>
                S1 Software Engineering
              </MenuItem>
              <MenuItem value={"S1 Teknik Industri"}>
                S1 Teknik Industri
              </MenuItem>
              <MenuItem value={"S1 Desain Komunikasi Visual"}>
                S1 Desain Komunikasi Visual
              </MenuItem>
            </Select>
            <FormHelperText>{error.program_studi}</FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            className={classes.marginTextfield}
            error={error.jalurPendaftaran_ittp ? true : false}
          >
            <InputLabel id="demo-simple-select-label">
              Jalur Pendaftaran
            </InputLabel>
            <Select
              name="jalurPendaftaran_ittp"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.jalurPendaftaran_ittp}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={"PMDK-1"}>PMDK-1</MenuItem>
              <MenuItem value={"PMDK-2"}>PMDK-2</MenuItem>
              <MenuItem value={"Beasiswa Unggul"}>Beasiswa Unggul</MenuItem>
              <MenuItem value={"Prestasi Siswa"}>Prestasi Siswa</MenuItem>
              <MenuItem value={"Seleksi Akhir"}>Seleksi Akhir</MenuItem>
            </Select>
            <FormHelperText>{error.jalurPendaftaran_ittp}</FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            className={classes.marginTextfield}
            error={error.beasiswa ? true : false}
          >
            <InputLabel id="demo-simple-select-label">Beasiswa</InputLabel>
            <Select
              name="beasiswa"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.beasiswa}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={"Prestasi Reguler"}>Prestasi Reguler</MenuItem>
              <MenuItem value={"Prestasi Tidak Mampu"}>
                Prestasi Tidak Mampu
              </MenuItem>
            </Select>
            <FormHelperText>{error.beasiswa}</FormHelperText>
          </FormControl>
          <FormControl
            fullWidth
            className={classes.marginTextfield}
            error={error.status ? true : false}
          >
            <InputLabel id="demo-simple-select-label">Status Fee</InputLabel>
            <Select
              name="status"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.status}
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value={"Cair"}>Cair</MenuItem>
              <MenuItem value={"Belum Cair"}>Belum Cair</MenuItem>
            </Select>
            <FormHelperText>{error.status}</FormHelperText>
          </FormControl>
          <TextField
            className={classes.marginTextfield}
            disabled={isSubmitting}
            id="fee"
            name="fee"
            label="Fee"
            InputProps={{
              inputComponent: RpFormat,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            autoComplete="off"
            value={form.fee}
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          Batal
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddDialog.propTypes = {
  dialog: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withRouter(AddDialog);
