import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import AppPageLoading from "../../../../components/AppPageLoading";
import { firestore } from "../../../../components/FirebaseProvider";

function TOCoin() {
  const [chartDataList, setChartDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChartData = async () => {
      const snapshot = await firestore
        .collection("tryout")
        .where("coin", ">", 0)
        .get();
      let monthDays = [];
      const dayInMonth = moment().daysInMonth();
      for (let i = 1; i <= dayInMonth; i++) {
        monthDays.push(i);
      }
      const chartData = snapshot.docs.map((doc) => {
        return {
          name: monthDays,
          score: doc.data().length,
        };
      });

      setChartDataList(chartData);
      setLoading(false);
    };
    fetchChartData();
  }, []);

  const BarChartReport = ({ data }) => {
    return (
      <Grid>
        <Typography>Report Siswa TO</Typography>
        {chartDataList.length > 0 ? (
          <BarChart width={800} height={600} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="score" fill="#8884d8" />
          </BarChart>
        ) : (
          <Typography>No data</Typography>
        )}
      </Grid>
    );
  };

  return (
    <>
      {loading ? <AppPageLoading /> : <BarChartReport data={chartDataList} />}
    </>
  );
}

export default TOCoin;
