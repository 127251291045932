import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { useCollection } from "react-firebase-hooks/firestore";
import { useFirebase } from "../../../components/FirebaseProvider";
import useStyles from "./styles";

import MaterialTable from "@material-table/core";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AppPageLoading from "../../../components/AppPageLoading";
import AddDialogC from "./addCategory";

import PeopleIcon from "@material-ui/icons/People";

// import CopyContentIcon from '@material-ui/icons/ContentCopy';
import { useSnackbar } from "notistack";
import { app_siswa_base } from "../../../config/urls";
import { formatDate } from "../../../utils/formatter";
import { currency } from "../../../utils/formatter-rupiah";
import getTableTitle from "../../../utils/getTableTitle";
import AddDialogN from "./addEvent";

const columns = {
  kategori: [
    { title: getTableTitle("Id"), field: "id" },
    { title: getTableTitle("Title"), field: "title" },
    {
      title: getTableTitle("Dibuat"),
      field: "createdAt",

      render: (rowData) => (
        <span>
          {formatDate(rowData.start_at)} s.d. {formatDate(rowData.end_at)}
        </span>
      ),
    },
    { title: getTableTitle("Berita Yang Di Pin"), field: "pinNews.title" },
  ],

  status: [
    { title: getTableTitle("Nama"), field: "nama" },
    {
      title: getTableTitle("Tanggal"),
      field: "start_at",

      render: (rowData) => (
        <span>
          {formatDate(rowData.start_at?.toMillis?.())} s.d.{" "}
          {formatDate(rowData.end_at?.toMillis?.())}
        </span>
      ),
    },
    { title: getTableTitle("Tipe"), field: "tipe" },
    {
      title: getTableTitle("Lokasi"),
      field: "lokasi_offline",

      render: (rowData) => (
        <span>{rowData[`lokasi_${rowData.tipe}`] ?? "offline"}</span>
      ),
    },
    {
      title: getTableTitle("Harga Tiket"),
      field: "harga_tiket_rp",

      render: (rowData) => (
        <span>
          {currency(rowData.harga_tiket_rp)}/{rowData.harga_tiket_coin} koin
        </span>
      ),
    },
    {
      title: "Peserta",
      sorting: false,
      render: (rowData) => {
        return (
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              width: 90,
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                rowData.history.push(`/event/peserta/${rowData.uid}`);
              }}
            >
              <PeopleIcon />
            </IconButton>
          </div>
        );
      },
    },
  ],
};

function Tabel() {
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { firestore, Arr } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const queryNews = firestore
    .collection("events")
    .where("status", "==", params.status);
  const [snapshot, loading] = useCollection(queryNews);

  const [table, setTable] = useState({
    columns: params.status === "kategori" ? columns.kategori : columns.status,
    data: [],
  });

  const [kategoriDialog, setKategoriDialog] = useState({
    open: false,
    mode: "Tambah",
    data: {},
  });

  const [openAddDialogN, setOpenAddDialogN] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);

  useEffect(() => {
    if (snapshot && !snapshot.empty) {
      if (params.status === "kategori") {
        setTable((table) => {
          return {
            ...table,
            columns: columns.kategori,
            data: snapshot.docs[0].data().items,
          };
        });
      } else {
        setTable((table) => {
          return {
            ...table,
            columns: columns.status,
            data: snapshot.docs.map((doc) => {
              return {
                uid: doc.id,
                ...doc.data(),
                history,
              };
            }),
          };
        });
      }
    } else {
      setTable((table) => {
        return {
          ...table,
          columns:
            params.status === "kategori" ? columns.kategori : columns.status,
          data: [],
        };
      });
    }
  }, [snapshot, params.status, history]);

  if (loading || loadingAction) {
    return <AppPageLoading />;
  }

  let title = "Event Diterbitkan";

  if (params.status === "draft") {
    title = "Draf Event";
  }
  if (params.status === "trash") {
    title = "Event Dihapus";
  }
  if (params.status === "kategori") {
    title = "Kategori Event";
  }

  return (
    <>
      <MaterialTable
        options={{ thirdSortClick: false }}
        title={title}
        columns={table.columns}
        data={table.data}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit",
            hidden: params.status === "trash",
            onClick: (event, data) => {
              if (params.status === "kategori") {
                setKategoriDialog({
                  mode: "Edit",
                  open: true,
                  data,
                });
              } else {
                history.push(`/event/edit/${data.uid}`);
              }
            },
          },
          {
            icon: "content_copy",
            tooltip: "Copy URL Event",
            hidden: params.status === "trash",
            onClick: (event, data) => {
              // if (params.status === "kategori") {
              //     setKategoriDialog({
              //         mode: 'Edit',
              //         open: true,
              //         data

              //     })

              // } else {
              //     history.push(`/event/edit/${data.uid}`);
              // }

              navigator.clipboard.writeText(
                `${app_siswa_base}acara/${data.uid}`
              );
              enqueueSnackbar(
                `Link Event berhasil disalin: ${app_siswa_base}acara/${data.uid}`,
                { variant: "success" }
              );
            },
          },
          {
            icon: "delete",
            tooltip: "Hapus",
            hidden: params.status === "trash",
            onClick: async (event, data) => {
              try {
                setLoadingAction(true);
                if (params.status === "kategori") {
                  if (!data.pinNews && !data.updatedAt) {
                    await firestore.doc(`news/kategori`).update({
                      items: Arr.arrayRemove({
                        id: data.id,
                        title: data.title,
                        createdAt: data.createdAt,
                      }),
                    });
                  } else {
                    await firestore.doc(`news/kategori`).update({
                      items: Arr.arrayRemove({
                        id: data.id,
                        title: data.title,
                        createdAt: data.createdAt,
                        pinNews: data.pinNews,
                        updatedAt: data.updatedAt,
                      }),
                    });
                  }
                } else {
                  await firestore
                    .doc(`events/${data.uid}`)
                    .set({ status: "trash" }, { merge: true });
                }
                enqueueSnackbar(`Berhasil, event dimasukkan ke sampah`, {
                  variant: "success",
                });
              } catch (e) {
                enqueueSnackbar(`Gagal hapus event, ${e.message}`, {
                  variant: "error",
                });
              } finally {
                setLoadingAction(false);
              }
            },
          },
          {
            icon: "restore",
            tooltip: "Kembalikan",
            hidden:
              params.status === "published" ||
              params.status === "draft" ||
              params.status === "kategori",
            onClick: async (event, data) => {
              await firestore
                .doc(`events/${data.uid}`)
                .set({ status: "draft" }, { merge: true });
            },
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: title,
            addTooltip: "Tambah",
            editTooltip: "Ubah",
            deleteTooltip: "Hapus",
            editRow: {
              deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
              cancelTooltip: "Batal",
              saveTooltip: "Ya",
            },
          },
          toolbar: {
            searchTooltip: "Cari",
            searchPlaceholder: "Cari Data",
          },
          header: {
            actions: "Tindakan",
          },
          pagination: {
            labelRowsSelect: "Baris",
            labelDisplayedRows: " {from}-{to} Dari {count} Baris",
            firstTooltip: "Halaman Pertama",
            previousTooltip: "Halaman Sebelumnya",
            nextTooltip: "Halaman Selanjutnya",
            lastTooltip: "Halaman Terakhir",
          },
        }}
      />
      {params.status === "kategori" && (
        <>
          <Fab
            className={classes.fab2}
            color="primary"
            onClick={() => {
              setKategoriDialog({
                mode: "Tambah",
                open: true,
                data: {},
              });
            }}
          >
            <CategoryOutlinedIcon />
          </Fab>

          <AddDialogC
            open={kategoriDialog.open}
            handleClose={() => {
              setKategoriDialog({
                //mode: 'Tambah',
                open: false,
                data: {},
              });
            }}
            items={table.data}
            mode={kategoriDialog.mode}
            data={kategoriDialog.data}
          />
        </>
      )}

      {params.status !== "kategori" && (
        <>
          <Fab
            className={classes.fab1}
            color="primary"
            onClick={() => {
              setOpenAddDialogN(true);
            }}
            tooltip
          >
            <PostAddIcon />
          </Fab>
          <AddDialogN
            open={openAddDialogN}
            handleClose={() => {
              setOpenAddDialogN(false);
            }}
            //items={table.data}
          />
        </>
      )}
    </>
  );
}

export default Tabel;
