import React, { useEffect, useState } from "react";

import useStyles from "./styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { useCollection } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import {
  functions,
  useFirebase,
  Timestamp,
} from "../../../components/FirebaseProvider";

import PageLoading from "../../../components/AppPageLoading";
import HarapanChart from "./harapan";

function Home() {
  const classes = useStyles();

  const { firestore } = useFirebase();
  const countDoc = firestore.collection("counters");
  const [count, loading] = useCollection(countDoc);
  const [harapan, setHarapan] = useState([]);
  const [countItems, setCountItems] = useState([]);
  const [utm, setUtm] = useState([]);
  const [tmbPlusList, setTmbPlusList] = useState([]);
  const [countTmbPlus, setCountTmbPlus] = useState([]);
  const [newStudents, setNewStudentsList] = useState([]);
  const [countTOFree, setCountTOFree] = useState([]);
  const [countTOCoin, setCountTOCoin] = useState([]);
  const [misi, setMisi] = useState([]);
  useEffect(() => {
    const countHarapan = functions.httpsCallable("countHarapan");
    countHarapan().then((value) => {
      setHarapan(
        Object.entries(value.data ?? {})
          .map((item) => {
            const [name, value] = item;
            return { name, value };
          })
          .filter((item) => {
            return !["null", "not_null", "total_siswa"].includes(item.name);
          })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const countUtm = functions.httpsCallable("countUtm");
    fetchData();
    countUtm().then((value) => {
      setUtm(
        Object.entries(value.data ?? {})
          .map((item) => {
            const [name, value] = item;
            // let newName = name;
            // if (name === 'null') {
            //     newName = 'direct';
            // }
            return { name, value };
          })
          .filter((item) => {
            return !["null", "not_null", "total_siswa", "direct"].includes(
              item.name
            );
          })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (count) {
      setCountItems(
        count.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        })
      );
    }
  }, [count]);

  async function fetchData() {
    try {
      const payments = [];
      const paymentRef = firestore.collection("payments");
      const settled = await paymentRef
        .where("transaction_status", "==", "settlement")
        .get();
      if (!settled.empty) {
        settled.forEach((doc) => {
          payments.push(doc.data());
        });
      }
      const captured = await paymentRef
        .where("transaction_status", "==", "capture")
        .where("fraud_status", "==", "accept")
        .get();
      if (!captured.empty) {
        captured.forEach((doc) => {
          payments.push(doc.data());
        });
      }
      if (payments.length > 0) {
        const result = payments.reduce((acc, cur) => {
          const mbtiType = cur.siswa?.mbti_type;
          if (mbtiType) {
            const index = acc.findIndex((item) => item.mbti_type === mbtiType);
            if (index !== -1) {
              acc[index].count++;
            } else {
              acc.push({ mbti_type: mbtiType, count: 1 });
            }
          }
          return acc;
        }, []);
        const totalCount = result.reduce((acc, cur) => acc + cur.count, 0);
        setTmbPlusList(result);
        setCountTmbPlus(totalCount);
      }
      const studentsRef = firestore.collection("siswa");
      const dateNow = new Date();
      const startDay = new Date(
        dateNow.getFullYear(),
        dateNow.getMonth(),
        dateNow.getDate()
      );
      const endDay = new Date(
        dateNow.getFullYear(),
        dateNow.getMonth(),
        dateNow.getDate() + 1
      );
      const studentsData = await studentsRef
        .where("dibuat_pada", ">=", Timestamp.fromDate(startDay))
        // .where("dibuat_pada", "<", Timestamp.fromDate(endDay))
        .get()
        .then((querySnapshot) => {
          const students = [];
          querySnapshot.forEach((doc) => {
            students.push(doc.data());
          });
          setNewStudentsList(students);
        })
        .catch((error) => {
          console.error(error);
        });
      const FreeTO = firestore.collection("tryout");
      const coinsFreeQuery = FreeTO.where("coin", "==", 0);

      const startOfDay = new Date();
      const endOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
      endOfDay.setHours(23, 59, 59, 999);
      const createdAtFreeQuery = FreeTO.where(
        "createdAt",
        ">=",
        Timestamp.fromDate(startOfDay)
      ).where("createdAt", ">=", Timestamp.fromDate(startOfDay));

      // execute both queries in parallel using Promise.all()
      const [coinsFreeSnapshot, createdAtFreeSnapshot] = await Promise.all([
        coinsFreeQuery.get(),
        createdAtFreeQuery.get(),
      ]);

      // filter the results to include only documents that match both queries
      const tryout = [];
      coinsFreeSnapshot.forEach((doc) => {
        const createdAt = doc.data().createdAt;
        const createdAtDoc = createdAtFreeSnapshot.docs.find(
          (d) => d.id === doc.id
        );
        if (createdAtDoc && createdAtDoc.data().createdAt === createdAt) {
          tryout.push(doc.data());
        }
      });
      setCountTOFree(tryout);
      const tryoutCoin = [];
      const coinTO = firestore.collection("tryout");
      const coinsQuery = coinTO.where("coin", ">", 0);
      const createdAtQuery = coinTO.where(
        "createdAt",
        ">=",
        Timestamp.fromDate(startOfDay)
      );

      const [coinsSnapshot, createdAtSnapshot] = await Promise.all([
        coinsQuery.get(),
        createdAtQuery.get(),
      ]);
      coinsSnapshot.forEach((doc) => {
        const createdAt = doc.data().createdAt;
        const createdAtDoc = createdAtSnapshot.docs.find(
          (d) => d.id === doc.id
        );
        if (createdAtDoc && createdAtDoc.data().createdAt === createdAt) {
          tryoutCoin.push(doc.data());
        }
      });
      setCountTOCoin(tryoutCoin);
      const misi = [];
      const misiDB = firestore.collection("misi");
      const misiData = await misiDB
        .where("tanggal", ">=", Timestamp.fromDate(startDay))
        .get();
      misiData.forEach((doc) => {
        misi.push(doc.data());
      });
      setMisi(misi);
    } catch (e) {
      console.log(e.message);
    }
  }

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <h1 className={classes.pageTitle}>Dashboard</h1>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={3}
        className={classes.cardC}
      >
        <Grid className={classes.gridContainerRatio}>
          <Grid className={classes.gridContent}>
            <Grid className={classes.gridContainerColumn3}>
              <Card className={classes.cardColor1}>
                <CardContent>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    className={classes.cardI}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.text1a}>
                        Jumlah Siswa Baru
                      </Typography>
                      <Typography className={classes.text1b}>
                        Yang Terdaftar di Aplikasi PakBudi
                      </Typography>
                      <Typography className={classes.text1c}>
                        {newStudents.length} Siswa
                      </Typography>
                      <Button
                        component={Link}
                        className={classes.button}
                        variant="outlined"
                        to="/chart/newStudent"
                      >
                        Lihat Detail
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.cardColor1}>
                <CardContent>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    className={classes.cardI}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.text1a}>
                        Jumlah Siswa TO Free
                      </Typography>
                      <Typography className={classes.text1b}>
                        Yang mengikut TO gratis di Aplikasi PakBudi
                      </Typography>
                      <Typography className={classes.text1c}>
                        {countTOFree.length} Gratis
                      </Typography>
                      <Button
                        component={Link}
                        className={classes.button}
                        variant="outlined"
                        to="/chart/ToFree"
                      >
                        Lihat Detail
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.cardColor1}>
                <CardContent>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    className={classes.cardI}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.text1a}>
                        Jumlah Siswa TO Coin
                      </Typography>
                      <Typography className={classes.text1b}>
                        Yang mengikut TO bercoin di Aplikasi PakBudi
                      </Typography>
                      <Typography className={classes.text1c}>
                        {countTOCoin.length} Bercoin
                      </Typography>
                      <Button
                        component={Link}
                        className={classes.button}
                        variant="outlined"
                        to="/chart/ToFree"
                      >
                        Lihat Detail
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.cardColor1}>
                <CardContent>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    className={classes.cardI}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.text1a}>
                        Jumlah Siswa Misi
                      </Typography>
                      <Typography className={classes.text1b}>
                        Yang mengerjakan misi di Aplikasi PakBudi
                      </Typography>
                      <Typography className={classes.text1c}>
                        {misi.length} Misi
                      </Typography>
                      <Button
                        component={Link}
                        className={classes.button}
                        variant="outlined"
                        to="/chart/Misi"
                      >
                        Lihat Detail
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {countItems.map((count) => {
                let title = "";
                let information = "";
                let link = "";

                if (count.id === "siswa") {
                  title = "Jumlah Akun Siswa";
                  information = "Siswa";
                  link = "/siswa";
                }
                if (count.id === "sekolah") {
                  title = "Jumlah SLTA";
                  information = "Sekolah";
                  link = "/data/sekolah";
                }
                if (count.id === "bk") {
                  title = "Jumlah Akun Guru BK";
                  information = "Guru";
                  link = "/akun/bk";
                }
                if (count.id === "peserta_event") {
                  title = "Jumlah Peserta Event";
                  information = "Peserta";
                }
                if (count.id === "mitra") {
                  title = "Jumlah Akun Mitra";
                  information = "Mitra";
                  link = "/akun/mitra";
                }
                let Count = count.count;
                let number_string = Count.toString();
                let sisa = number_string.length % 3;
                let number = number_string.substr(0, sisa);
                let ribuan = number_string.substr(sisa).match(/\d{3}/g);
                if (ribuan) {
                  let separator = sisa ? "." : " ";
                  number += separator + ribuan.join(".");
                }
                return (
                  <Card key={count.id} className={classes.cardColor1}>
                    <CardContent>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                        className={classes.cardI}
                      >
                        <Grid item xs={12}>
                          <Typography className={classes.text1a}>
                            {title}
                          </Typography>
                          <Typography className={classes.text1b}>
                            Yang Terdaftar di Aplikasi PakBudi
                          </Typography>
                          <Typography className={classes.text1c}>
                            {number} {information}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Button
                            component={Link}
                            className={classes.button}
                            variant="outlined"
                            to={link}
                            // disabled={count.id === "siswa"}
                          >
                            Lihat Detail
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
            </Grid>
          </Grid>
          <Grid className={classes.gridContent}>
            <Grid className={classes.gridContainer}>
              <Card className={classes.cardColor1}>
                <CardContent>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    className={classes.cardI}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.text1a}>
                        Jumlah Permintaan TMB+
                      </Typography>
                      <Typography className={classes.text1b}>
                        Yang sudah bayar di aplikasi pakbudi
                      </Typography>
                      <Typography className={classes.text1c}>
                        {countTmbPlus} Permintaan
                      </Typography>
                      {tmbPlusList.map((item, index) => {
                        let arrayTMB = [
                          "ENFP",
                          "ESFP",
                          "INFJ",
                          "INTJ",
                          "INTP",
                          "ISFP",
                          "ISTJ",
                          "ENTP",
                          "ESTP",
                          "ISTP",
                          "ESTJ",
                          "ESFJ",
                          "ENTJ",
                          "ISFJ",
                          "INFP",
                          "ENFJ",
                        ];
                        // Memeriksa apakah mbti_type terdapat dalam arrayTMB
                        let isTMB = arrayTMB.includes(item.mbti_type);
                        return (
                          <div key={index}>
                            <Typography
                              style={{ color: isTMB ? "green" : "inherit" }}
                            >
                              {index + 1}. {item.mbti_type} - {item.count}{" "}
                              Permintaan
                            </Typography>
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.cardColor1} xs={12} sm={4}>
                <CardContent>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    className={classes.cardI}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.text1a}>
                        Harapan
                      </Typography>
                      <Typography className={classes.text1b}>
                        Siswa Yang Terdaftar di Aplikasi PakBudi
                      </Typography>
                      <div>
                        <HarapanChart data={harapan} />
                      </div>
                    </Grid>
                    <Grid
                      container
                      justify="flex-start"
                      alignItems="flex-start"
                    ></Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card className={classes.cardColor1}>
                <CardContent>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    className={classes.cardI}
                  >
                    <Grid item xs={12}>
                      <Typography className={classes.text1a}>
                        UTM Source
                      </Typography>
                      <Typography className={classes.text1b}>
                        Aplikasi PakBudi Siswa
                      </Typography>
                      <div>
                        <HarapanChart data={utm} />
                      </div>
                    </Grid>
                    {/* <Grid container justifyContent="flex-start" alignItems="flex-start">
                        <Button
                            component={Link}
                            className={classes.btn}
                            variant="outlined"
                            // to={link}
                            disabled={count.id === "siswa"}
                        >
                            Lihat Detail
                        </Button>
                    </Grid> */}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Home;
