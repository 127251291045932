import React, { useEffect, useState } from "react";

// MUI
import MaterialTable from "@material-table/core";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import BackIcon from "@material-ui/icons/ArrowBack";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
// Styles
import useStyles from "./styles";

// Utils
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useFirebase } from "../../../../components/FirebaseProvider";
// import { useSnackbar } from 'notistack';

// Component
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import AppPageLoading from "../../../../components/AppPageLoading";
import Dialog from "./dialog";

// MUI
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import queryString from "query-string";
import { formatDate } from "../../../../utils/formatter";
import { currency } from "../../../../utils/formatter-rupiah";
import getTableTitle from "../../../../utils/getTableTitle";

function Routing() {
  const location = useLocation();
  const history = useHistory();
  // const { location, history } = props;
  const handleChangeTab = (event, value) => {
    // history.push(value);
    history.push({ search: "?tab=" + value });
  };

  const [tabValue, setTabValue] = useState("fee");

  useEffect(() => {
    if (location.search) {
      const qParams = queryString.parse(location.search);

      const tab = qParams.tab ?? "fee";
      const tabList = ["fee", "pencairan"];

      if (tabList.includes(tab)) {
        setTabValue(tab);
      } else {
        setTabValue("fee");
      }
    }
  }, [location.search]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ alignSelf: "flex-start" }}>
        <Button
          onClick={() => {
            history.push("/akun/mitra");
          }}
        >
          <BackIcon style={{ marginRight: 5 }} />
          Kembali
        </Button>
      </div>
      <Paper square style={{ marginBottom: 50 }}>
        <Tabs
          centered
          variant="fullWidth"
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
        >
          <Tab label="Riwayat Komisi" value="fee" />
          <Tab label="Riwayat Pencairan" value="pencairan" />
        </Tabs>
        <Divider />
        <div>
          <Switch>
            <Route
              path="/akun/mitra/detail/:mitra_id"
              render={() => <MitraDetail tabValue={tabValue} />}
            />
            <Redirect
              to={{
                search: "?tab=fee",
              }}
            />
          </Switch>
        </div>
      </Paper>
    </div>
  );
}

function MitraDetail({ tabValue }) {
  const classes = useStyles();
  const { firestore } = useFirebase();
  // const {enqueueSnackbar} = useSnackbar();
  const params = useParams();

  // const feeDoc = firestore.doc(`koin_mitra/${params.mitra_id}`);
  const feeItemsDoc = firestore.collection(
    `koin_mitra/${params.mitra_id}/items`
  );
  // const [fee, loadingFee] = useDocumentData(feeDoc)
  const [feeItems, loadingFeeItems] = useCollectionData(
    feeItemsDoc.orderBy("createdAt", "desc")
  );
  const queryPencairan = firestore.collection("pencairan_mitra");
  const [pencairanItems, loadingPencairan] = useCollectionData(
    queryPencairan
      .where("mitra_id", "==", params.mitra_id)
      .orderBy("created_at", "desc")
  );
  const [dialog, setDialog] = useState({
    mode: "Tambah",
    open: false,
    data: {},
    uid: {},
  });

  const [table, setTable] = React.useState({
    columns: [],
    data: [],
  });

  useEffect(() => {
    if (tabValue === "fee") {
      const feeTable = {
        columns: [
          { title: getTableTitle("Dari"), field: "from" },
          { title: getTableTitle("Koin"), field: "coin" },
          {
            title: getTableTitle("Dibuat"),
            field: "createdAt",
            defaultSort: "desc",
            render: (rowData) => (
              <span>{formatDate(rowData.createdAt?.toMillis?.() ?? 0)}</span>
            ),
          },
        ],
        data: feeItems || [],
      };

      setTable(feeTable);
    } else if (tabValue === "pencairan") {
      setTable({
        columns: [
          { title: getTableTitle("Nama Bank"), field: "bank" },
          { title: getTableTitle("Nomor Rekening"), field: "no_rekening" },
          { title: getTableTitle("Nama Pemilik"), field: "nama_pemilik" },
          {
            title: getTableTitle("Nominal Pencairan"),
            field: "total_pencairan",
            render: (rowData) => (
              <>
                <span>{currency(rowData.total_pencairan)}</span>
              </>
            ),
          },
          {
            title: getTableTitle("Status"),
            field: "status",
            render: (rowData) => (
              <>
                {rowData.status === "requested" ? (
                  <span>Menunggu Diproses</span>
                ) : rowData.status === "success" ? (
                  <span>Pencairan Berhasil</span>
                ) : (
                  <span>Pencairan Ditolak</span>
                )}
              </>
            ),
          },
          {
            title: getTableTitle("Dibuat"),
            field: "createdAt",
            defaultSort: "desc",
            render: (rowData) => (
              <span>{formatDate(rowData.createdAt?.toMillis?.() ?? 0)}</span>
            ),
          },
        ],
        data: pencairanItems || [],
      });
    } else {
      setTable({
        columns: [],
        data: [],
      });
    }
  }, [tabValue, feeItems, pencairanItems]);

  if (loadingFeeItems || loadingPencairan) {
    return <AppPageLoading />;
  }

  return (
    <>
      <MaterialTable
        options={{ thirdSortClick: false }}
        title={tabValue === "fee" ? "Data Komisi" : " Data Pencairan"}
        columns={table.columns}
        data={table.data}
        localization={{
          body: {
            emptyDataSourceMessage: "Tidak Ada Data",
            addTooltip: "Tambah",
            editTooltip: "Ubah",
            deleteTooltip: "Hapus",
            editRow: {
              deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
              cancelTooltip: "Batal",
              saveTooltip: "Ya",
            },
          },
          toolbar: {
            searchTooltip: "Cari",
            searchPlaceholder: "Cari Data",
          },
          header: {
            actions: "Tindakan",
          },
          pagination: {
            labelRowsSelect: "Baris",
            labelDisplayedRows: " {from}-{to} Dari {count} Baris",
            firstTooltip: "Halaman Pertama",
            previousTooltip: "Halaman Sebelumnya",
            nextTooltip: "Halaman Selanjutnya",
            lastTooltip: "Halaman Terakhir",
          },
        }}
      />
      <Fab
        className={classes.fab}
        color="primary"
        onClick={(e) => {
          setDialog({ mode: "Tambah", open: true, data: {} });
        }}
      >
        <PersonAddOutlinedIcon />
      </Fab>
      <Dialog
        dialog={dialog}
        handleClose={() => {
          setDialog({ mode: "Tambah", open: false, data: {} });
        }}
      />
    </>
  );
}

export default Routing;
