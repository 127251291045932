// React
import React from 'react';

// Image
import mainImage from '../../images/error.png';

// Material UI
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

// Styles
import useStyles from './styles/index';

function NotFound() {
    const classes = useStyles();
    return(
        <Container maxWidth="sm">
                <div className={classes.content}>
                    <img
                        src={mainImage}
                        alt=""
                        width="100vw"
                    />
                    <Typography className={classes.text1}>Yah,</Typography>
                    <Typography className={classes.text2}>Anda Nyasar !</Typography>
                    <Typography className={classes.text3}>Mungkin Permintaan Anda Salah.<br/>Cek Permintaan Anda, Atau Tekan Tombol Dibawah Untuk Kembali</Typography>

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={()=>{window.history.back()}}
                    >
                        Kembali
                    </Button>
                </div>
        </Container>
    )
}

export default NotFound;