import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import MenuIcon from "@material-ui/icons/Menu";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import GameIcon from "@material-ui/icons/SportsEsports";
import StorageIcon from "@material-ui/icons/Storage";
import clsx from "clsx";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useFirebase } from "../../components/FirebaseProvider";
import TopBar from "../../components/TopBar2";
import Akun from "./akun";
import Broadcast from "./broadcast";
import Data from "./data";
import Esport from "./esport";
import Event from "./event";
import Home from "./home";
import Migration from "./migration";
import Misi from "./misi";
import News from "./news";
import Pencairan from "./Pencairan Fee";
import PendaftarITTP from "./pendaftarITTP";
import Promo from "./Promo Coin";
import useStyles from "./styles";
import TemplateChat from "./templateChat";
import Siswa from './siswa';
import transaksi from "./transaksi";
import TransaksiEvent from "./transaksi-event";
import Tryout from "./tryout";
import Voucher from "./voucher";
import Chart from "./chart";

function Private(props) {
  const classes = useStyles();
  const { auth, role } = useFirebase();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Dialog Box
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleLogout = (e) => {
    auth.signOut();
  };

  let activerole = "admin";
  if (role && role.data() && role.data().akses === "cs") {
    activerole = "cs";
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <Tooltip title="Luaskan Menu" placement="right">
            <IconButton
              edge="start"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Typography
            component="h1"
            variant="h6"
            noWrap
            className={classes.title}
          >
            <Switch>
              <Route path="/akun" children="Pengelola Akun" />
              <Route path="/affiliate" children="Affiliate" />
              <Route path="/order" children="Order" />
              <Route path="/news" children="Pengelola Berita" />
              <Route path="/tryout" children="Pengelola Tryout" />
              <Route path="/data/sekolah" children="Pengelola Data Sekolah" />
              <Route
                path="/data/jurusan-kuliah"
                children="Pengelola Data Jurusan Universitas"
              />
              <Route path="/transaksi" children="Pengelola Transaksi" />
              <Route path="/misi" children="Pengelola Misi" />
              <Route path="/voucher" children="Pengelola Voucher" />
              <Route path="/promocoin" children="Pengelola Promo" />
              <Route path="/pencairanfee" children="Pengelola Pencairan Fee" />
              <Route path="/pendaftarITTP" children="Pendaftar ITTP" />
              <Route path="/templateChat" children="Template Chat" />
              <Route path="/broadcast" children="Broadcast Notification" />
              <Route path="/siswa" children="Pengelola Data Siswa" />
              <Route path="/esport" children="Pengelola E-Sport" />
              <Route path="/event" children="Pengelola Event" />
              <Route path="/transaksi-event" children="Transaksi Event" />
              <Route path="/migration" children="Migration" />
              <Route children="Home" />
            </Switch>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <TopBar />
          <Tooltip title="Sempitkan Menu" placement="right">
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Divider />
        <List>
          {activerole === "admin" && (
            <>
              <Route
                path="/home"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Beranda" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => history.push("/")}
                      >
                        <ListItemIcon>
                          <HomeOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Beranda" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/akun"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Pengelola Akun" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/akun");
                        }}
                      >
                        <ListItemIcon>
                          <PeopleOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Akun" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/news"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Berita" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/news");
                        }}
                      >
                        <ListItemIcon>
                          <LibraryBooksOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Berita" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/tryout"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Tryout" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/tryout");
                        }}
                      >
                        <ListItemIcon>
                          <LocalLibraryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tryout" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/data"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Data" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/data");
                        }}
                      >
                        <ListItemIcon>
                          <FolderOpenOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Data" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/transaksi"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Transaksi" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/transaksi");
                        }}
                      >
                        <ListItemIcon>
                          <AccountBalanceWalletOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Transaksi" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/misi"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Misi" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/misi");
                        }}
                      >
                        <ListItemIcon>
                          <AssignmentTurnedInIcon />
                        </ListItemIcon>
                        <ListItemText primary="Misi" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/esport"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="E-Sport" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/esport");
                        }}
                      >
                        <ListItemIcon>
                          <GameIcon />
                        </ListItemIcon>
                        <ListItemText primary="E-Sport" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/event"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Event" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/event");
                        }}
                      >
                        <ListItemIcon>
                          <CalendarTodayIcon />
                        </ListItemIcon>
                        <ListItemText primary="Event" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/transaksi-event"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Transaksi Event" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/transaksi-event");
                        }}
                      >
                        <ListItemIcon>
                          <EventAvailableIcon />
                        </ListItemIcon>
                        <ListItemText primary="Transaksi Event" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/broadcast"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Broadcast Notification" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/broadcast");
                        }}
                      >
                        <ListItemIcon>
                          <SettingsInputAntennaIcon />
                        </ListItemIcon>
                        <ListItemText primary="Broadcast Notification" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route path="/siswa" children={({ match, history }) => {

                  return (
                      <Tooltip title="Siswa" placement="right">
                          <ListItem
                              button
                              selected={match ? true : false}
                              onClick={() => {
                                  history.push('/siswa')
                              }}
                          >
                              <ListItemIcon>
                                  <PeopleOutlinedIcon />
                              </ListItemIcon>
                              <ListItemText primary="Siswa" />
                          </ListItem>
                      </Tooltip>
                  )
              }} />
            </>
          )}

          {activerole === "admin" && (
            <>
              <Route
                path="/pencairanfee"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Pencairann Fee" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/pencairanfee");
                        }}
                      >
                        <ListItemIcon>
                          <LocalAtmOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pencairan Fee" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/voucher"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Voucher" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/voucher");
                        }}
                      >
                        <ListItemIcon>
                          <ConfirmationNumberOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Voucher" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/promocoin"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Promo Coin" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/promocoin");
                        }}
                      >
                        <ListItemIcon>
                          <MonetizationOnOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Promo Coin" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/pendaftarITTP"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Data Pendaftar ITTP" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/pendaftarITTP");
                        }}
                      >
                        <ListItemIcon>
                          <SchoolOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pendaftar ITTP" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/templateChat"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Template Chat" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/templateChat");
                        }}
                      >
                        <ListItemIcon>
                          <ForumOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Template Chat" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
              <Route
                path="/migration"
                children={({ match, history }) => {
                  return (
                    <Tooltip title="Migration" placement="right">
                      <ListItem
                        button
                        selected={match ? true : false}
                        onClick={() => {
                          history.push("/migration");
                        }}
                      >
                        <ListItemIcon>
                          <StorageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Migration" />
                      </ListItem>
                    </Tooltip>
                  );
                }}
              />
            </>
          )}
          <Tooltip title="Logout" placement="right">
            <ListItem button onClick={handleClickOpen}>
              <ListItemIcon>
                <ExitToAppOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>
      <main className={classes.content} id="main-content">
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route component={Home} path="/home" />
            <Route component={News} path="/news" />
            <Route component={Tryout} path="/tryout" />
            <Route component={Akun} path="/akun" />
            <Route component={Data} path="/data" />
            <Route component={transaksi} path="/transaksi" />
            <Route component={Voucher} path="/voucher" />
            <Route component={Promo} path="/promocoin" />
            <Route component={Pencairan} path="/pencairanfee" />
            <Route component={PendaftarITTP} path="/pendaftarITTP" />
            <Route component={TemplateChat} path="/templateChat" />
            <Route component={Misi} path="/misi" />
            <Route component={Broadcast} path="/broadcast" />
            <Route component={Esport} path="/esport" />
            <Route component={Event} path="/event" />
            <Route component={TransaksiEvent} path="/transaksi-event" />
                        <Route component={Siswa} path="/siswa" />
            <Route component={Migration} path="/migration" />
            <Route component={Chart} path="/chart" />
            <Redirect to="/home" />
          </Switch>
        </Container>
      </main>

      {/* Dialog Box Logout */}

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.dialog}
      >
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <ReportProblemOutlinedIcon fontSize="large" color="secondary" />
            </Grid>
          </Grid>
          <DialogTitle id="alert-dialog-slide-title">
            <Typography style={{ textAlign: "center" }}>
              Apakah Anda Yakin?
            </Typography>
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <ButtonGroup fullWidth variant="text">
            <Button onClick={handleClose} color="primary">
              Tidak
            </Button>
            <Button onClick={handleLogout}>Logout</Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Private;
