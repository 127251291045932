import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// material-ui

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDocument } from "react-firebase-hooks/firestore";
import { useFirebase } from "../../../components/FirebaseProvider";

// utils
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

function AddDialogN({ open, handleClose }) {
  const history = useHistory();

  const { firestore } = useFirebase();

  const queryKategori = firestore.doc("news/kategori");
  const [kategoriSnapshot, loading] = useDocument(queryKategori);

  const [form, setForm] = useState({
    title: "",
    kategori: {},
    status: "draft",
    publishedAt: "",
    fee: 0,
  });

  const [error, setError] = useState({
    title: "",
    fee: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setSubmitting] = useState(false);

  const [kategoriOptions, setKategoriOptions] = useState([]);

  useEffect(() => {
    if (kategoriSnapshot) {
      setKategoriOptions(
        kategoriSnapshot.data() &&
          kategoriSnapshot.data().items &&
          kategoriSnapshot
            .data()
            .items.map((item) => ({ id: item.id, title: item.title }))
      );
    }
  }, [kategoriSnapshot]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const handleAutocompleteChange = (name) => (e, v) => {
    handleChange({ target: { value: v, name } });
  };

  const validate = async () => {
    const newError = { ...error };
    const { title, kategori } = form;

    if (!title) {
      newError.title = "Masukkan Judul";
    }

    if (!kategori) {
      newError.kategori = "Pilih Kategori Berita";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();
    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        const newsCol = firestore.collection(`news`);
        const newNews = await newsCol.add({
          ...form,
          createdAt: Date.now(),
          updatedAt: Date.now(),
          status: "draft",
        });

        enqueueSnackbar("Berita Berhasil Dibuat", { variant: "success" });
        history.push(`/news/edit/${newNews.id}`);
      } catch (e) {
        const newError = {};

        newError.title = e.message;

        setError(newError);
      }

      setSubmitting(false);
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={(_event, reason) => {
        if (reason !== "backdropClick" || !isSubmitting) {
          handleClose();
        }
      }}
    >
      <DialogTitle>Buat Berita Baru</DialogTitle>
      <DialogContent dividers>
        <form id="create-admin-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={isSubmitting}
                autoComplete="off"
                id="title"
                name="title"
                label="Judul Berita"
                fullWidth
                variant="outlined"
                value={form.title}
                onChange={handleChange}
                helperText={error.title}
                error={error.title ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="kategoriOptions"
                options={kategoriOptions}
                getOptionLabel={(option) => {
                  if (!option.title) {
                    return "";
                  }
                  return option.title;
                }}
                value={form.kategori}
                onChange={handleAutocompleteChange("kategori")}
                name="kategori"
                debug={true}
                disableClearable
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Kategori Berita"
                    variant="outlined"
                    fullWidth
                    helperText={error.kategori}
                    error={error.kategori ? true : false}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={isSubmitting}
                autoComplete="off"
                id="fee"
                name="fee"
                label="Fee Berita"
                fullWidth
                variant="outlined"
                value={form.fee}
                onChange={handleChange}
                helperText={error.fee}
                error={error.fee ? true : false}
                inputProps={{
                  type: "number",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          Batal
        </Button>
        <Button
          form="create-admin-form"
          disabled={isSubmitting}
          type="submit"
          color="primary"
        >
          Buat
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddDialogN.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddDialogN;
