import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import useStyles from "./styles";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import { firestore } from "../../../components/FirebaseProvider";

import MaterialTable from "@material-table/core";
import Avatar from "@material-ui/core/Avatar";
import Switch from "@material-ui/core/Switch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSnackbar } from "notistack";
import AppPageLoading from "../../../components/AppPageLoading";
import { formatDate } from "../../../utils/formatter";
import { delimiters } from "../../../utils/formatter-rupiah";
import getTableTitle from "../../../utils/getTableTitle";
const defaultSiswaRef = firestore.collection("siswa").doc("default");

function KetColumn({ keterangan }) {
  const classes = useStyles();
  const [isActive, setActive] = useState(false);
  const handleMore = () => {
    setActive(!isActive);
  };

  if (keterangan?.length <= 100) {
    return (
      <div>
        <div
          className={classes.closed}
          dangerouslySetInnerHTML={{ __html: keterangan }}
        ></div>
      </div>
    );
  }
  return (
    <div>
      <div
        className={isActive ? classes.expanded : classes.closed}
        dangerouslySetInnerHTML={{ __html: keterangan }}
      ></div>
      <button className={classes.btnMore} onClick={handleMore}>
        {isActive ? "Hide..." : "More..."}
      </button>
    </div>
  );
}

const columns = {
  kelola: [
    { title: getTableTitle("ID"), field: "id" },
    {
      title: getTableTitle("Nama"),
      field: "nama",
      render: (rowData) => <span>{delimiters(rowData.nama)}</span>,
    },
    { title: getTableTitle("Email"), field: "email" },
    { title: getTableTitle("Telepon"), field: "no_hp" },
    {
      title: "Default",
      sorting: false,
      render: (rowData) => {
        return (
          <>
            <Switch
              checked={rowData.nama === rowData.nama}
              onChange={() => {
                defaultSiswaRef.set(
                  {
                    nama: rowData.nama,
                  },
                  { merge: true }
                );
              }}
              name={"checked-" + rowData?.nama}
              inputProps={{ "aria-label": "default" }}
            />
          </>
        );
      },
    },
  ],
  review: [
    { title: getTableTitle("ID"), field: "siswa.id" },
    { title: getTableTitle("Nama"), field: "siswa.nama" },
    { title: getTableTitle("Email"), field: "siswa.email" },
    { title: getTableTitle("Telepon"), field: "siswa.no_hp" },
  ],
};
function Tabel() {
  const history = useHistory();

  const params = useParams();

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const querySiswa = firestore.collection("siswa").limit(100);

  const [data, loading] = useCollection(querySiswa);

  const [table, setTable] = useState({
    columns: [
      {
        title: getTableTitle("ID"),
        field: "uid",
        render: (rowData) => (
          <>
            {rowData && rowData.uid !== undefined ? (
              <span>{rowData.uid}</span>
            ) : (
              <span>Tidak Ada ID</span>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Nama"),
        field: "nama",
        render: (rowData) => (
          <>
            {rowData && rowData.nama !== undefined ? (
              <span>{rowData.nama}</span>
            ) : (
              <span>Tidak Ada Nama</span>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Email"),
        field: "email",
        render: (rowData) => (
          <>
            {rowData && rowData.email !== undefined ? (
              <span>{rowData.email}</span>
            ) : (
              <span>Tidak Ada Email</span>
            )}
          </>
        ),
      },
      {
        title: getTableTitle("Telepon"),
        field: "no_hp",
        render: (rowData) => (
          <>
            {rowData && rowData.no_hp !== undefined ? (
              <span>{rowData.no_hp}</span>
            ) : (
              <span>Tidak Ada Telepon</span>
            )}
          </>
        ),
      },
    ],
    data: [],
  });

  useEffect(() => {
    if (data && data.docs) {
      setTable((table) => {
        return {
          ...table,
          data: data.docs.map((doc) => {
            return {
              uid: doc.id,
              ...doc.data(),
            };
          }),
        };
      });
    }
  }, [data, params.status]);

  let title = "Data Siswa";

  if (loading) {
    return <AppPageLoading />;
  }

  return (
    <>
      <MaterialTable
        options={{ thirdSortClick: false }}
        title={title}
        columns={table.columns}
        data={table.data}
        actions={[]}
        localization={{
          body: {
            emptyDataSourceMessage: `Tidak Ada ${title}`,
            addTooltip: "Tambah",
            editTooltip: "Ubah",
            deleteTooltip: "Hapus",
            editRow: {
              deleteText: "Anda Yakin Akan Menghapus Data Ini ?",
              cancelTooltip: "Batal",
              saveTooltip: "Ya",
            },
          },
          toolbar: {
            searchTooltip: "Cari",
            searchPlaceholder: "Cari Data",
          },
          header: {
            actions: "Tindakan",
          },
          pagination: {
            labelRowsSelect: "Baris",
            labelDisplayedRows: " {from}-{to} Dari {count} Baris",
            firstTooltip: "Halaman Pertama",
            previousTooltip: "Halaman Sebelumnya",
            nextTooltip: "Halaman Selanjutnya",
            lastTooltip: "Halaman Terakhir",
          },
        }}
      />
    </>
  );
}

export default Tabel;
