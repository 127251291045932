import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Manage from './manage';

function siswa() {
    return (
        <Switch>
            <Route exact path="/siswa/nama" component={Manage} />
            <Redirect to="/siswa/nama" />
        </Switch>
    )
}

export default siswa;