// React
import React, { useCallback, useState } from "react";

// React-DropZone
import { useDropzone } from "react-dropzone";

// Styles
import useStyles from "./styles/uploadFoto";

// CircularProgress
import CircularProgress from "@material-ui/core/CircularProgress";

// Notification
// import { useSnackbar } from 'notistack';

// Input, Icons & Images
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

// Avatar
import Avatar from "@material-ui/core/Avatar";

// Axios
import axios from "axios";
import { firestore, functions } from "../../../components/FirebaseProvider";

function UploadFoto({ form, uid, onChange }) {
  const classes = useStyles();

  // Profie
  // const { profile } = useMitra();

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const accept = ["image/png", "image/jpeg"];

  const maxSize = "20971520";

  // Snackbar
  // const { enqueueSnackbar } = useSnackbar();

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setError("");
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onabort = () => {
        setError("Pembacaan file dibatalkan");
      };
      reader.onerror = () => {
        setError("Pembacaan file gagal");
      };
      reader.onload = (ev) => {
        setLoading(true);
        try {
          const img = new Image();

          img.src = ev.target.result;

          img.onload = () => {
            const elem = document.createElement("canvas");
            const width = img.width; //250;
            // const ratio = img.width / img.height;
            const height = img.height; //width / ratio;
            elem.width = width;

            elem.height = height;

            const ctx = elem.getContext("2d");

            ctx.drawImage(img, 0, 0, width, height);

            ctx.canvas.toBlob(
              async (blob) => {
                const compressedfile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });

                const mitCol = firestore.collection(`esport`);

                let newUid = uid;
                if (!uid) {
                  newUid = mitCol.doc().id;
                }
                const generateUploadUrl =
                  functions.httpsCallable("generateUploadUrl");

                const result = await generateUploadUrl({
                  filename: file.name,
                  directory: `images/esport/`,
                  contentType: file.type,
                  id: newUid,
                });

                await axios.put(result.data.uploadUrl, compressedfile, {
                  headers: {
                    "Content-Type": file.type,
                  },
                });
                onChange({
                  target: { value: result.data.photoUrl, name: "gambar" },
                });
                onChange({ target: { value: newUid, name: "uid" } });

                setLoading(false);
              },
              file.type,
              0.5
            );
          };
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      reader.readAsDataURL(file);
    },
    [onChange, uid]
  );

  const onDropRejected = useCallback(
    (rejected) => {
      if (!accept.includes(rejected[0].type)) {
        setError(`Tipe file tidak didukung : ${rejected[0].type} `);
      } else if (rejected[0].size >= maxSize) {
        setError(`Ukuran file terlalu besar > 20Mb`);
      }
    },
    [accept, maxSize]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    maxSize,
    disabled: loading,
  });

  return (
    <>
      <div {...getRootProps()} className={classes.avatarChange}>
        <input {...getInputProps()} />
        {form.gambar && (
          <Avatar
            variant="square"
            alt={form ? form.judul : "Img"}
            src={form.gambar}
            className={classes.avatarImg}
          />
        )}
        {!form.gambar && (
          <Avatar
            variant="square"
            className={classes.avatarImg}
            style={{ backgroundColor: "#0E5F96" }}
          >
            {"U"}
          </Avatar>
        )}

        <label htmlFor="uploud">
          <IconButton
            disabled={loading}
            className={classes.change}
            component="span"
          >
            <PhotoCameraIcon className={classes.icoCamera} />
          </IconButton>
        </label>
        {loading && (
          <CircularProgress size={24} className={classes.fabProgress} />
        )}
      </div>
      {error && <Typography color="error">{error}</Typography>}
    </>
  );
}

export default UploadFoto;
