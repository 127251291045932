import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState, useEffect } from "react";
import AppPageLoading from "../../../../components/AppPageLoading";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { firestore, Timestamp } from "../../../../components/FirebaseProvider";

function NewStudent() {
  const [chartDataList, setChartDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const dateNow = new Date();
        const startOfMonth = new Date(
          dateNow.getFullYear(),
          dateNow.getMonth(),
          1
        );
        const snapshot = await firestore
          .collection("siswa")
          .where("dibuat_pada", ">=", Timestamp.fromDate(startOfMonth))
          .get();
        let monthDays = [];
        const dayInMonth = moment().daysInMonth();
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        for (let i = 1; i <= dayInMonth; i++) {
          const value = `${year}-${month.toString().padStart(2, "0")}-${i}`;
          monthDays.push(value);
        }
        let chartData = [];
        snapshot.forEach((doc) => {
          if (typeof doc.data().dibuat_pada !== "undefined") {
            const dateObj = new Date(doc.data().dibuat_pada.seconds * 1000);
            const formattedDate = dateObj.toISOString().substr(0, 10);
            let item = {
              createTime: formattedDate,
            };
            chartData.push(item);
          }
        });
        const groupedData = chartData.reduce((acc, curr) => {
          const existingItemIndex = acc.findIndex(
            (item) => item.createTime === curr.createTime
          );
          if (existingItemIndex === -1) {
            acc.push({ createTime: curr.createTime, total: 1 });
          } else {
            acc[existingItemIndex].total++;
          }
          return acc;
        }, []);

        const result = monthDays
          .filter((item) => {
            return groupedData.some((value) => {
              return item == value.createTime;
            });
          })
          .map((item) => {
            const score = groupedData.filter(
              (value) => value.createTime == item
            ).length;
            return { name: item, score: score };
          });
        setChartDataList(result);
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    };
    fetchChartData();
  }, []);
  console.log(chartDataList);
  const BarChartReport = ({ data }) => {
    return (
      <Grid>
        <Typography>Report Siswa Baru</Typography>
        {chartDataList.length > 0 ? (
          <BarChart width={700} height={550} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="score" fill="#8884d8" />
          </BarChart>
        ) : (
          <Typography>No data</Typography>
        )}
      </Grid>
    );
  };

  return (
    <>
      {loading ? <AppPageLoading /> : <BarChartReport data={chartDataList} />}
    </>
  );
}

export default NewStudent;
