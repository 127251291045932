import MaterialTable from "@material-table/core";
import { IconButton } from "@material-ui/core";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import AppPageLoading from "../../../components/AppPageLoading";
import { firestore } from "../../../components/FirebaseProvider";
import errorMessageFactory from "../../../utils/errorMessageFactory";
import MigrateDialog from "./MigrateDialog";

const Tabel = () => {
  const [filter, setFilter] = useState({
    slug: "",
    open: false,
    isLoading: false,
  });
  const [dataMigration, setDataMigration] = useState([]);
  const [apiLink, setApiLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [lastHasilTryoutDoc, setLastHasilTryoutDoc] = useState(null);
  const table = {
    columns: [
      {
        title: "Modul",
        field: "modul",
      },
      {
        title: "Migrasi",
        sorting: false,
        render: useCallback(
          (rowData) => (
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                width: 90,
              }}
            >
              <IconButton
                onClick={() => {
                  setFilter({
                    open: true,
                    slug: rowData.slug,
                  });
                }}
              >
                <SystemUpdateAltIcon />
              </IconButton>
            </div>
          ),
          [setFilter]
        ),
      },
    ],
    data: [
      { id: 1, modul: "Kategori Soal", slug: "kategori_soal" },
      { id: 2, modul: "Tryout", slug: "tryout" },
      { id: 3, modul: "Soal", slug: "soal" },
      { id: 4, modul: "Siswa", slug: "siswa" },
      { id: 5, modul: "Hasil Tryout", slug: "hasil_tryout" },
    ],
  };
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getMigrationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.slug]);

  async function handleMigrate() {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://pakbudi.coralisstudio.com/api/${apiLink}`,
        { data: dataMigration },
        { headers: { "X-API-KEY": "coralisxpakbudi2023RestAPI" } }
      );
      const { message } = response.data;
      enqueueSnackbar(message, { variant: "success" });
    } catch (e) {
      const message = errorMessageFactory(e);
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setFilter((filter) => ({
        ...filter,
        open: false,
      }));
      setLoading(false);
    }
  }

  async function getMigrationData() {
    try {
      setFilter((filter) => ({
        ...filter,
        isLoading: true,
      }));
      let collectionRef = "";
      switch (filter.slug) {
        case "kategori_soal":
          collectionRef = firestore.collection("kategori_soal");
          setApiLink("import_kategori_soal");
          await getDefaultData(collectionRef);
          break;
        case "tryout":
          collectionRef = firestore.collection("tryout");
          setApiLink("import_tryout");
          await getDefaultData(collectionRef);
          break;
        case "soal":
          collectionRef = firestore.collection("soal");
          setApiLink("import_soal");
          await getSoalData(collectionRef);
          break;
        case "siswa":
          collectionRef = firestore.collection("siswa");
          setApiLink("import_siswa");
          await getSiswaDataChunk(collectionRef);
          break;
        case "hasil_tryout":
          collectionRef = firestore.collection("hasil_tryout");
          setApiLink("import_siswa_tryout");
          await getHasilTryoutData(collectionRef);
          break;
        default:
          break;
      }
    } catch (e) {
      const message = errorMessageFactory(e);
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setFilter((filter) => ({
        ...filter,
        isLoading: false,
      }));
    }
  }

  let tempData = [];
  async function getSiswaDataChunk(collectionRef) {
    try {
      const query = lastDoc
        ? collectionRef.orderBy("nama", "asc").startAfter(lastDoc)
        : collectionRef.orderBy("nama", "asc");
      const snapshot = await query.limit(5000).get();

      if (snapshot.empty) {
        return;
      }
      const docs = snapshot.docs.map((doc) => doc.data());
      // console.log(docs);
      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);

      const result = snapshot.docs.map((doc) => {
        return {
          docId: doc.id,
          link: apiLink,
          ...doc.data(),
        };
      });
      tempData.push(result);
      console.log(tempData);
      setDataMigration(tempData);
      if (docs.length === 5000) {
        await getSiswaDataChunk(collectionRef);
      }
    } catch (error) {
      throw error;
    }
  }

  async function getDefaultData(collectionRef) {
    try {
      const rawResult = await collectionRef.get();
      const result = rawResult.docs.map((doc) => {
        return {
          docId: doc.id,
          link: apiLink,
          ...doc.data(),
        };
      });
      setDataMigration(result);
    } catch (e) {
      throw e;
    }
  }

  async function getSoalData(collectionRef) {
    try {
      const rawResult = await collectionRef.get();
      const result = [];
      for (const doc of rawResult.docs) {
        const soalData = doc.data();
        const kunciJawabanDoc = await firestore
          .collection("kunci_jawaban")
          .doc(doc.id)
          .get();
        const kunciJawabanData = kunciJawabanDoc.exists
          ? kunciJawabanDoc.data()
          : null;
        const responseData = {
          docId: doc.id,
          ...soalData,
          jawaban: kunciJawabanData ? kunciJawabanData.jawaban : null,
          pembahasan: kunciJawabanData?.penjelasan
            ? kunciJawabanData.penjelasan
            : null,
        };
        result.push(responseData);
      }
      setDataMigration(result);
    } catch (e) {
      throw e;
    }
  }
  const result = [];
  async function getHasilTryoutData(collectionRef) {
    try {
      const query = lastHasilTryoutDoc
        ? collectionRef
            .orderBy("created_at", "asc")
            .startAfter(lastHasilTryoutDoc)
        : collectionRef.orderBy("created_at", "asc");
      const snapshot = await query.limit(5000).get();
      if (snapshot.empty) {
        return;
      }
      const docs = snapshot.docs.map((doc) => doc.data());
      setLastHasilTryoutDoc(snapshot.docs[snapshot.docs.length - 1]);

      for (const doc of snapshot.docs) {
        const hasilData = doc.data();
        const jawabanSiswaDoc = await firestore
          .collection("hasil_tryout")
          .doc(doc.id)
          .collection("jawaban_siswa")
          .get();
        let jawabanSiswaArr = [];
        jawabanSiswaDoc.forEach((snap) => {
          jawabanSiswaArr.push(snap.data());
        });
        const responseData = {
          docId: doc.id,
          ...hasilData,
          jawaban_siswa: jawabanSiswaArr,
        };
        result.push(responseData);
      }
      if (docs.length === 5000) {
        await getHasilTryoutData(collectionRef);
      }
      console.log(result);
      setDataMigration(result);
    } catch (e) {
      throw e;
    }
  }

  if (loading) {
    return <AppPageLoading />;
  }

  return (
    <>
      <MaterialTable
        title="Migrate SQL"
        columns={table.columns}
        data={table.data}
      />
      <MigrateDialog
        {...filter}
        handleClose={() => {
          setFilter({
            slug: "",
            open: false,
            isLoading: false,
          });
        }}
        handleMigrate={handleMigrate}
      />
    </>
  );
};

export default Tabel;
