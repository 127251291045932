import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/index';
import mainLogo from '../../images/logo2.svg';
import LinearProgress from '@material-ui/core/LinearProgress';

const AppLoading = (props) => {

    const { classes, } = props;
    
    return (
        <div className={classes.root}>
            <div className={classes.loadingBody}>
                <img className={classes.logoImg} src={mainLogo} alt="" />
                <div className={classes.loadingProgress}>
                    <LinearProgress className={classes.progress} color="primary" />
                    <p className={classes.loadingText}>roles loading...</p>
                </div>
            </div>
        </div>
    )
}


export default withStyles(styles)(AppLoading);