//
import React, { useEffect, useState } from "react";

// material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

// utils
import DateFnsUtils from "@date-io/date-fns";
import { useSnackbar } from "notistack";
import { Arr, firestore } from "../../../components/FirebaseProvider";
import { Numberformat } from "../../../components/NumberFormat";

function AddDialogN({ open, handleClose, mode, data }) {
  const [form, setForm] = useState({
    judul: "",
    coin: "",
    status: "draft",
    random: false,
    order: "",
    waktu: "",
    url: "",
    kategori: "none",
    hide_ranking: false,
  });

  const [selectedDate, setSelectedDate] = useState();

  const [error, setError] = useState({
    judul: "",
    coin: "",
    waktu: "",
    order: "",
  });

  useEffect(() => {
    if (mode === "Edit") {
      setForm({
        uid: data.uid,
        judul: data.judul,
        coin: data.coin.toString(),
        status: data.status,
        random: data.random,
        order: data.order,
        waktu: data.waktu / 60,
        url: data.url ? data.url : "",
        kategori: data?.kategori ? data.kategori : "none",
        hide_ranking: data.hasOwnProperty("hide_ranking") ? data.hide_ranking : false,
      });
      setSelectedDate(
        data.release_date && new Date(data.release_date.toMillis())
      );
    } else {
      setForm({
        judul: "",
        coin: "",
        status: "draft",
        random: false,
        order: "",
        waktu: "",
        url: "",
        kategori: "none",
        hide_ranking: false,
      });
      setSelectedDate(new Date());
    }
  }, [mode, data]);

  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setSubmitting] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (e) => {
    let value;
    if (e.target.value === "true") {
      value = true;
    } else if (e.target.value === "false") {
      value = false;
    } else {
      value = e.target.value;
    }
    setForm({
      ...form,
      [e.target.name]: value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const validate = async () => {
    const newError = { ...error };
    const { judul, coin, waktu, order } = form;

    if (!judul) {
      newError.judul = "Judul Wajib Di isi";
    }

    if (!coin) {
      newError.coin = "Harga Wajib Di isi";
    }

    if (!waktu) {
      newError.waktu = "Waktu Wajib Di isi";
    }

    if (!order) {
      newError.order = "Urutan Wajib Di isi";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        if (mode === "Edit") {
          const tryoutCol = firestore.collection(`tryout`);
          const {
            judul,
            status,
            coin,
            order,
            random,
            waktu,
            url,
            kategori,
            hide_ranking,
          } = {
            ...form,
          };
          if (status === "coming soon") {
            await tryoutCol.doc(form.uid).set(
              {
                judul,
                coin: parseInt(coin),
                waktu: parseInt(waktu * 60),
                status,
                release_date: selectedDate,
                order: parseInt(order),
                random: JSON.parse(random),
                updatedAt: Arr.serverTimestamp(),
                url: url || "",
                kategori,
                hide_ranking,
              },
              { merge: true }
            );
          } else {
            await tryoutCol.doc(form.uid).set(
              {
                judul,
                coin: parseInt(coin),
                waktu: parseInt(waktu * 60),
                status,
                order: parseInt(order),
                random: JSON.parse(random),
                updatedAt: Arr.serverTimestamp(),
                url: url || "",
                kategori,
                hide_ranking,
              },
              { merge: true }
            );
          }

          enqueueSnackbar("Tryout Berhasil Diperbarui", { variant: "success" });
        } else {
          const tryoutCol = firestore.collection(`tryout`);
          const {
            judul,
            status,
            coin,
            order,
            random,
            waktu,
            kategori,
            hide_ranking,
          } = {
            ...form,
          };

          if (status === "coming soon") {
            await tryoutCol.add({
              judul,
              coin: parseInt(coin),
              waktu: parseInt(waktu * 60),
              status,
              release_date: selectedDate,
              order: parseInt(order),
              random,
              createdAt: Arr.serverTimestamp(),
              updatedAt: Arr.serverTimestamp(),
              jumlah_soal: 0,
              url: "",
              kategori,
              hide_ranking,
            });
          } else {
            await tryoutCol.add({
              judul,
              coin: parseInt(coin),
              waktu: parseInt(waktu * 60),
              status,
              order: parseInt(order),
              random,
              createdAt: Arr.serverTimestamp(),
              updatedAt: Arr.serverTimestamp(),
              jumlah_soal: 0,
              url: "",
              kategori,
              hide_ranking,
            });
          }

          enqueueSnackbar("Tryout Berhasil Dibuat", { variant: "success" });
        }
        handleClose();
      } catch (e) {
        enqueueSnackbar(`Gagal membuat tryout, ${e.message}`, {
          variant: "error",
        });
      }

      setSubmitting(false);
    }
  };

  let title = "Buat Tryout Baru";
  let btn = "Buat";

  if (mode === "Edit") {
    title = "Edit Tryout";
    btn = "Perbarui";
  }

  return (
    <Dialog
      disableBackdropClick={isSubmitting}
      disableEscapeKeyDown={isSubmitting}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <form id="create-admin-form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                disabled={isSubmitting}
                autoComplete="off"
                id="judul"
                name="judul"
                label="Judul Tryout"
                fullWidth
                variant="outlined"
                value={form.judul}
                onChange={handleChange}
                helperText={error.judul}
                error={error.judul ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={isSubmitting}
                autoComplete="off"
                id="coin"
                name="coin"
                InputProps={{
                  inputComponent: Numberformat,
                }}
                label="Harga"
                fullWidth
                variant="outlined"
                value={form.coin}
                onChange={handleChange}
                helperText={error.coin}
                error={error.coin ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={isSubmitting}
                autoComplete="off"
                id="waktu"
                name="waktu"
                InputProps={{
                  inputComponent: Numberformat,
                }}
                label="Waktu (menit)"
                fullWidth
                variant="outlined"
                value={form.waktu}
                onChange={handleChange}
                helperText={error.waktu}
                error={error.waktu ? true : false}
              />
            </Grid>
            {mode === "Edit" && (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Random Soal
                  </InputLabel>
                  <Select
                    native
                    value={form.random}
                    onChange={handleChange}
                    label="Random Soal"
                    inputProps={{
                      name: "random",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option value={true}>Ya</option>
                    <option value={false}>Tidak</option>
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                disabled={isSubmitting}
                autoComplete="off"
                id="order"
                name="order"
                InputProps={{
                  inputComponent: Numberformat,
                }}
                label="Urutan Di Home"
                fullWidth
                variant="outlined"
                value={form.order}
                onChange={handleChange}
                helperText={error.order}
                error={error.order ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth disabled={isSubmitting} variant="outlined">
                <InputLabel htmlFor="native-simple">Status Tryout</InputLabel>
                <Select
                  native
                  value={form.status}
                  onChange={handleChange}
                  label="Status Tryout"
                  inputProps={{
                    name: "status",
                    id: "native-simple",
                  }}
                >
                  <option value={"published"}>Aktif</option>
                  <option value={"draft"}>Tidak Aktif</option>
                  <option value={"coming soon"}>Coming Soon</option>
                </Select>
              </FormControl>
            </Grid>
            {form.status === "coming soon" && (
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    margin="normal"
                    fullWidth
                    label="Tanggal Rilis"
                    format="dd/MM/yyyy HH:mm"
                    value={selectedDate}
                    disabled={isSubmitting}
                    onChange={handleDateChange}
                    error={error.release_date ? true : false}
                    helperText={error.release_date}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            )}
            {mode === "Edit" && (
              <Grid item xs={12}>
                <TextField
                  disabled={isSubmitting}
                  autoComplete="off"
                  id="url"
                  name="url"
                  label="URL Hasil Tryout (Opsional)"
                  fullWidth
                  variant="outlined"
                  value={form.url}
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth disabled={isSubmitting} variant="outlined">
                <InputLabel htmlFor="native-simple">Kategori</InputLabel>
                <Select
                  native
                  value={form.kategori}
                  onChange={handleChange}
                  label="kategori"
                  inputProps={{
                    name: "kategori",
                    id: "native-simple",
                  }}
                >
                  <option value={"none"}>Tanpa Kategori</option>
                  <option value={"snbt"}>Tryout SNBT</option>
                  <option value={"beasiswa"}>Kompetisi Beasiswa</option>
                  <option value={"olimpiade"}>Olimpiade</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth disabled={isSubmitting} variant="outlined">
                <InputLabel htmlFor="native-simple">
                  Hide Result and Answer
                </InputLabel>
                <Select
                  native
                  value={form.hide_ranking}
                  onChange={handleChange}
                  label="Hide Result and Answer"
                  inputProps={{
                    name: "hide_ranking",
                    id: "native-simple",
                  }}
                >
                  <option value={"false"}>Tidak</option>
                  <option value={"true"}>Ya</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          Batal
        </Button>
        <Button
          form="create-admin-form"
          disabled={isSubmitting}
          type="submit"
          color="primary"
        >
          {btn}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddDialogN;
